import { useNavigate, Link } from "react-router-dom";
import { useState, useEffect, useRef } from "react";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Loading from "../components/LoadingScreen";

import regada_picture from "../images/logoTYPOGRAPHIA.svg";
import first_landing from "../images/pexels-chevanon-1108101.jpg";
import second_landing from "../images/pexels-pixabay-159298.jpg";

import axios from "axios";

const Selector = () => {
  const serverAdresa = "https://vps.regada.sk/";
  // const serverAdresa = "http://localhost:8000/";

  const jazyky = ["SK", "EN", "RU"];

  const [selectedLanguage, setSelectedLanguage] = useState("SK");

  const handleLanguageChange = (event) => {
    setSelectedLanguage(event.target.value); // Pass the selected language back to the parent component
  };

  let navigate = useNavigate();
  const [typove, setTypove] = useState("260");
  const [vsetkyServopohony, setVsetkyServopohony] = useState(null);
  const [vsetkyServopohonyMena, setVsetkyServopohonyMena] = useState(null);

  const prveSpustenie = useRef(false);

  async function getAllServa() {
    try {
      const response = await axios.get(`${serverAdresa}vsetko`);
      setVsetkyServopohony(response.data.map((item) => item.kod));
      setVsetkyServopohonyMena(response.data.map((item) => item.nazov));
    } catch (e) {
      console.log(e);
    }
  }

  useEffect(() => {
    if (prveSpustenie.current === false) {
      getAllServa();
      return () => (prveSpustenie.current = true);
    }
  }, []);

  return (
    <>
      {vsetkyServopohony && vsetkyServopohonyMena && (
        <div className="hlavnyLanding">
          <div className="navLanding">
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                padding: "20px",
                borderBottom: "1px solid #E8E6E2",
              }}
            >
              <img
                src={regada_picture}
                id="typografia_header"
                alt="typography"
              ></img>
              <div>
                <div style={{ display: "flex", gap: "6px" }}>
                  <button
                    className="button-18"
                    onClick={() => navigate("/menu")}
                  >
                    {selectedLanguage === "EN"
                      ? "Employees"
                      : selectedLanguage === "RU"
                      ? "Сотрудники"
                      : "Zamestnanci"}
                  </button>
                  <select
                    value={selectedLanguage}
                    onChange={handleLanguageChange}
                    name="language"
                    id="language_header"
                  >
                    {jazyky.map((e) => {
                      return <option value={e}>{e}</option>;
                    })}
                  </select>
                </div>
              </div>
            </div>
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "70px",
              gap: "60px",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "start",
                alignItems: "start",
                gap: "20px",
              }}
            >
              <button className="button-19">
                {selectedLanguage === "EN"
                  ? "New on the market"
                  : selectedLanguage === "RU"
                  ? "Новое на рынке"
                  : "Novinka na trhu"}
              </button>
              <h1 className="landingHeadline">
                {selectedLanguage === "EN"
                  ? "Modern services for customers"
                  : selectedLanguage === "RU"
                  ? "Современные услуги для клиентов"
                  : "Moderné služby pre zákazníkov"}
              </h1>
              <p style={{ fontSize: "16px", width: "420px", color: "#71726E" }}>
                {selectedLanguage === "EN"
                  ? "Try out new functionalities such as the interactive configurator, order code decipherer or diagram generator."
                  : selectedLanguage === "RU"
                  ? "Попробуйте новые функции, такие как интерактивный конфигуратор, расшифровщик кода заказа или генератор диаграмм."
                  : "Vyskúšajte si nové funkcionality ako interaktívny konfigurátor, dešifrovač objednávacieho kódu alebo generátor schém."}
              </p>
              <div className="buttony">
                <Box sx={{ maxWidth: 120 }}>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">
                      Typové
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={typove}
                      label="Typove"
                      onChange={(e) => {
                        setTypove(e.target.value);
                      }}
                    >
                      {vsetkyServopohony.map((e) => {
                        return <MenuItem value={e}>{e}</MenuItem>;
                      })}
                    </Select>
                  </FormControl>
                </Box>
                {/* <button class="button-18" role="button">Button 18</button> */}
                <Link to={`/serva/${typove}`}>
                  <button
                    className="button-18"
                    // onClick={() => navigate(`/serva/${typove}`)}
                  >
                    {selectedLanguage === "EN"
                      ? "Configure"
                      : selectedLanguage === "RU"
                      ? "Настроить"
                      : "Konfigurovať"}{" "}
                    {vsetkyServopohonyMena[vsetkyServopohony.indexOf(typove)]}
                  </button>
                </Link>

                <button
                  className="button-18"
                  onClick={() => navigate("/search")}
                >
                  {selectedLanguage === "EN"
                    ? "Decipherer"
                    : selectedLanguage === "RU"
                    ? "Дешифратор"
                    : "Dešifrovač"}
                </button>

                <button
                  className="button-18"
                  onClick={() => navigate("/schemy")}
                >
                  {selectedLanguage === "EN"
                    ? "Diagrams"
                    : selectedLanguage === "RU"
                    ? "Схема"
                    : "Schémy"}
                </button>
              </div>
            </div>
            <div
              style={{
                position: "relative",
                width: "460px",
                height: "auto",
                marginTop: "30px",
              }}
            >
              <img
                src={first_landing}
                alt="f"
                style={{
                  width: "460px",
                  position: "absolute",
                  top: "0",
                  left: "0",
                }}
              />
              <img
                src={second_landing}
                alt="f"
                style={{
                  width: "200px",
                  position: "absolute",
                  top: "200px",
                  left: "300px",
                }}
              />
            </div>
          </div>
        </div>
      )}
      {!vsetkyServopohony && !vsetkyServopohonyMena && <Loading />}
    </>
  );
};

export default Selector;
