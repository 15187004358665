import Preklad from "../Preklad";
import Tooltip from "@mui/material/Tooltip";

const Vybavenie = ({ vybaveniaData, slovnik, selectedLanguage, poznamka }) => {
  return vybaveniaData.map((elem, i) => {
    if (elem !== "") {
      if (elem[0] === elem[0].toUpperCase()) {
        if (elem.includes(")")) {
          let rozdelene = elem.split(" ");
          let poznamky = rozdelene.filter(
            (e) => e.includes(")") & !isNaN(e.slice(0, -1))
          );
          let text_hodnota = rozdelene
            .filter((e) => !poznamky.includes(e))
            .join(" ");

          return (
            <li key={i + "r"}>
              {" "}
              <div style={{ display: "flex", gap: "3px" }}>
                <div>
                  <Preklad
                    hodnota={text_hodnota}
                    jazyk={selectedLanguage}
                    prekladac={slovnik}
                  />
                </div>
                <div style={{ fontSize: "10px", display: "flex", gap: "2px" }}>
                  {poznamky.map((jednaPoznamka, indexJednejPoznamky) => {
                    let poznamkaUpravena = "";
                    if (poznamka) {
                      if (poznamka[jednaPoznamka.slice(0, -1)]) {
                        poznamkaUpravena = poznamka[jednaPoznamka.slice(0, -1)];
                      } else {
                        poznamkaUpravena = "Poznámka nebola nájdena";
                      }
                    } else {
                      poznamkaUpravena = "Poznámka nebola nájdena";
                    }
                    return (
                      <Tooltip
                        title={
                          <Preklad
                            hodnota={poznamkaUpravena}
                            jazyk={selectedLanguage}
                            prekladac={slovnik}
                          />
                        }
                      >
                        <div>{jednaPoznamka}</div>
                      </Tooltip>
                    );
                  })}
                </div>
              </div>
            </li>
          );
        } else {
          return (
            <li key={i + "r"}>
              {" "}
              <Preklad
                hodnota={elem}
                jazyk={selectedLanguage}
                prekladac={slovnik}
              />
            </li>
          );
        }
      } else {
        if (elem.includes(")")) {
          let rozdelene = elem.split(" ");
          let poznamky = rozdelene.filter(
            (e) => e.includes(")") & !isNaN(e.slice(0, -1))
          );
          let text_hodnota = rozdelene
            .filter((e) => !poznamky.includes(e))
            .join(" ");

          return (
            <ul>
              <li key={i + "r"}>
                {" "}
                <div style={{ display: "flex", gap: "3px" }}>
                  <div>
                    <Preklad
                      hodnota={text_hodnota}
                      jazyk={selectedLanguage}
                      prekladac={slovnik}
                    />
                  </div>
                  <div
                    style={{ fontSize: "10px", display: "flex", gap: "2px" }}
                  >
                    {poznamky.map((jednaPoznamka, indexJednejPoznamky) => {
                      let poznamkaUpravena = "";
                      if (poznamka) {
                        if (poznamka[jednaPoznamka.slice(0, -1)]) {
                          poznamkaUpravena =
                            poznamka[jednaPoznamka.slice(0, -1)];
                        } else {
                          poznamkaUpravena = "Poznámka nebola nájdena";
                        }
                      } else {
                        poznamkaUpravena = "Poznámka nebola nájdena";
                      }
                      return (
                        <Tooltip
                          title={
                            <Preklad
                              hodnota={poznamkaUpravena}
                              jazyk={selectedLanguage}
                              prekladac={slovnik}
                            />
                          }
                        >
                          <div>{jednaPoznamka}</div>
                        </Tooltip>
                      );
                    })}
                  </div>
                </div>
              </li>
            </ul>
          );
        } else {
          return (
            <ul>
              <li key={i + "r"}>
                {" "}
                <Preklad
                  hodnota={elem}
                  jazyk={selectedLanguage}
                  prekladac={slovnik}
                />
              </li>
            </ul>
          );
        }
      }
    }
    return null;
  });
};

export default Vybavenie;
