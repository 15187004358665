import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";

import Loading from "../components/LoadingScreen";
import OknoEdit from "../components/OknoEdit";

import regada_picture from "../images/logoTYPOGRAPHIAwhite.svg";
import sipka from "../images/icons8-arrow.svg";
import { useLocation, useNavigate } from "react-router-dom";

import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";

import InfoIcon from "@mui/icons-material/Info";
import DocumentaciaDialog from "../components/DocumentaciaDialog";

import Button from "@mui/material/Button";

import TextField from "@mui/material/TextField";

import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";

import AlertDialogSlide from "../components/AlertDialogSlide";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";

import EditTabulka from "../components/EditovanieComp/Edit_Tabulka";

const Editovanie = () => {
  const serverAdresa = "https://vps.regada.sk/";
  // const serverAdresa = "http://localhost:8000/";

  const navigate = useNavigate();

  let zaheslovane = false;
  // let zaheslovane = false;

  const { cisloTypove } = useParams();
  const [typove, setTypove] = useState(cisloTypove);
  const [open, setOpen] = useState(false);

  const [openDocumentacia, setOpenDocumentacia] = useState(false);

  // bez pomlcky
  const [showModal, setShowModal] = useState(false);
  const [vsetkyServopohony, setVsetkyServopohony] = useState(null);
  const [vsetkyUdaje, setVsetkyUdaje] = useState(null);

  const [mazanie, setMazanie] = useState(false);
  const [stlpce, setStlpce] = useState(false);

  const [presuvanie, setPresuvanie] = useState(false);

  const [kombinaciaKey, setKombinaciaKey] = useState("");
  const [kombinaciaValue, setKombinaciaValue] = useState("");

  const classyTabulky = [
    "prva",
    "druha",
    "tretia",
    "stvrta",
    "piata",
    "siesta",
  ];

  const [nacitava, setNacitava] = useState(false);

  const deleteKombinacia = (keyToDelete) => {
    const newObj = { ...vsetkyUdaje }; // Create a shallow copy of the object
    delete newObj.tabulka7.kombinacie[keyToDelete]; // Delete the specified key
    setVsetkyUdaje(newObj); // Update the state with the new object
  };

  // CALLBACK na update vsetkyudaje
  const updateVsetkyUdaje = (newVsetkyUdaje) => {
    setVsetkyUdaje(newVsetkyUdaje);
  };

  const handleBeforeUnload = (event) => {
    event.preventDefault();
    const confirmationMessage = "Ste si istý že chcete opustiť stránku ?";
    event.returnValue = confirmationMessage;
    return confirmationMessage;
  };

  async function getServo() {
    try {
      const response = await axios.get(`${serverAdresa}standard`, {
        params: { typove },
      });

      if (response.data.vybavenie) {
        if (!response.data.vybavenie.standardne.includes("")) {
          response.data.vybavenie.standardne = [
            ...response.data.vybavenie.standardne,
            "",
          ];
        }
        if (!response.data.vybavenie.rozsirene.includes("")) {
          response.data.vybavenie.rozsirene = [
            ...response.data.vybavenie.rozsirene,
            "",
          ];
        }
      } else {
        response.data.vybavenie.standardne = [""];
        response.data.vybavenie.rozsirene = [""];
      }
      setVsetkyUdaje(response.data);
    } catch (e) {
      console.log(e);
    }
  }

  async function getAllServa() {
    try {
      const response = await axios.get(`${serverAdresa}vsetko`);
      setVsetkyServopohony(response.data);
      // setVsetkyServopohony(response.data.map((item) => item.kod));
    } catch (e) {
      console.log(e);
    }
  }

  useEffect(() => {
    getAllServa();
  }, []);

  useEffect(() => {
    getServo();
  }, [typove]);

  const handleServoChange = (e) => {
    setTypove(e.target.value);
  };

  const handlePopUp = (e) => {
    setOpen(e);
  };

  const handleDocumentacia = (e) => {
    setOpenDocumentacia(e);
  };

  // useEffect(() => {
  //   window.addEventListener("beforeunload", handleBeforeUnload);
  //   return () => {
  //     window.removeEventListener("beforeunload", handleBeforeUnload);
  //   };
  // }, []);

  return (
    <>
      {vsetkyUdaje && !nacitava && vsetkyServopohony && (
        <>
          <div className={showModal ? "zoslabenie" : ""}>
            <div id="headerup">
              {" "}
              <img
                id="back_header"
                src={sipka}
                alt="sipka"
                onClick={() =>
                  navigate("/menu", {
                    state: {
                      zaheslovane,
                    },
                  })
                }
                style={{ cursor: "pointer" }}
              ></img>
              <img
                src={regada_picture}
                id="typografia_header"
                alt="typography"
                onClick={() =>
                  navigate("/menu", {
                    state: {
                      zaheslovane,
                    },
                  })
                }
                style={{ cursor: "pointer" }}
              ></img>
              <div style={{ width: "60px" }}></div>
            </div>
            <DocumentaciaDialog
              openDocumentacia={openDocumentacia}
              handleDocumentacia={handleDocumentacia}
              ktoraDocumentacia={"edit"}
            />

            <AlertDialogSlide
              open={open}
              sprava="Servopohon bol úspešne upravený"
            />

            <div className="obalena_tab1">
              <div
                style={{
                  margin: "auto",
                  marginTop: "12px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    gap: "10px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      gap: "10px",
                      alignItems: "center",
                      marginBottom: "10px",
                    }}
                  >
                    <div
                      className="nazov"
                      style={{
                        textAlign: "center",
                        fontFamily: "CamptonBold",
                        fontSize: "20px",
                      }}
                    >
                      Editovanie
                    </div>
                    <InfoIcon
                      htmlColor="#005e9d"
                      onClick={() => setOpenDocumentacia(true)}
                      style={{ cursor: "pointer" }}
                    />
                  </div>

                  <Box sx={{ minWidth: 120 }}>
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">
                        Typ.
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={typove}
                        label="Typove"
                        onChange={(e) =>
                          (window.location.href = `/editovanie/${e.target.value}`)
                        }
                      >
                        {vsetkyServopohony.map((e) => {
                          return <MenuItem value={e.kod}>{e.kod}</MenuItem>;
                        })}
                      </Select>
                    </FormControl>
                  </Box>
                </div>
              </div>

              <>
                <div className="nazov_obrazok">
                  <div className="detail">
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <div style={{ display: "flex", gap: "20px" }}>
                        <TextField
                          label="Typové"
                          variant="standard"
                          style={{ width: "60px", marginBottom: "10px" }}
                          value={vsetkyUdaje.kod}
                          placeholder="typové"
                          onChange={(e) =>
                            setVsetkyUdaje({
                              ...vsetkyUdaje,
                              kod: e.target.value,
                            })
                          }
                        />
                        <div>
                          <h2>
                            Kategória:{" "}
                            <i>
                              {vsetkyUdaje.kategoria.charAt(0).toUpperCase() +
                                vsetkyUdaje.kategoria.slice(1)}
                            </i>
                          </h2>
                          <h2>
                            Otáčky:{" "}
                            <i>
                              {vsetkyUdaje.otacky === "jedno"
                                ? "Jednootáčkové"
                                : vsetkyUdaje.otacky === "viac"
                                ? "Viacotáčkové"
                                : "Priamočiare"}
                            </i>
                          </h2>
                        </div>
                      </div>

                      <TextField
                        variant="outlined"
                        id="nazov"
                        value={vsetkyUdaje.nazov}
                        // placeholder="Názov"
                        onChange={(e) =>
                          setVsetkyUdaje({
                            ...vsetkyUdaje,
                            nazov: e.target.value,
                          })
                        }
                        style={{ marginBottom: "6px" }}
                      />
                    </div>

                    <div id="vybavenia_obvidva" style={{ marginTop: "6px" }}>
                      <div>
                        <h2
                          className="vybavenia_nazvy"
                          style={{ marginBottom: "10px" }}
                        >
                          Štandardné vybavenie:
                        </h2>
                        {vsetkyUdaje.vybavenie.standardne.length > 0 && (
                          <div
                            style={{ display: "flex", flexDirection: "column" }}
                          >
                            {" "}
                            {vsetkyUdaje.vybavenie.standardne.map((elem, i) => {
                              return (
                                <div style={{ height: "24px" }}>
                                  <input
                                    className="vybavenie-edit"
                                    style={{ height: "24px" }}
                                    key={i + "s"}
                                    value={elem}
                                    onChange={(e) => {
                                      let zmena = [
                                        ...vsetkyUdaje.vybavenie.standardne,
                                      ];
                                      zmena[i] = e.target.value;
                                      if (!zmena.includes("")) {
                                        zmena = [...zmena, ""];
                                      }
                                      setVsetkyUdaje({
                                        ...vsetkyUdaje,
                                        vybavenie: {
                                          ...vsetkyUdaje.vybavenie,
                                          standardne: zmena,
                                        },
                                      });
                                    }}
                                  ></input>
                                  <button
                                    hidden={
                                      vsetkyUdaje.vybavenie.standardne.length -
                                        1 ===
                                      i
                                        ? true
                                        : false
                                    }
                                    className="tlacitko-vymazat-male"
                                    style={{ marginLeft: "5px" }}
                                    onClick={(e) => {
                                      let zmena = [
                                        ...vsetkyUdaje.vybavenie.standardne,
                                      ];
                                      zmena = zmena.filter(
                                        (e, index) => i !== index
                                      );
                                      setVsetkyUdaje({
                                        ...vsetkyUdaje,
                                        vybavenie: {
                                          ...vsetkyUdaje.vybavenie,
                                          standardne: zmena,
                                        },
                                      });
                                    }}
                                  >
                                    x
                                  </button>
                                </div>
                              );
                            })}
                          </div>
                        )}
                      </div>

                      <div>
                        <h2 className="vybavenia_nazvy">
                          Rozšírené vybavenie:
                        </h2>
                        {vsetkyUdaje.vybavenie.rozsirene.length > 0 && (
                          <div
                            style={{ display: "flex", flexDirection: "column" }}
                          >
                            {" "}
                            {vsetkyUdaje.vybavenie.rozsirene.map((elem, i) => {
                              return (
                                <div style={{ height: "24px" }}>
                                  <input
                                    className="vybavenie-edit"
                                    style={{ height: "24px" }}
                                    key={i + "r"}
                                    value={elem}
                                    onChange={(e) => {
                                      let zmena = [
                                        ...vsetkyUdaje.vybavenie.rozsirene,
                                      ];
                                      zmena[i] = e.target.value;
                                      if (!zmena.includes("")) {
                                        zmena = [...zmena, ""];
                                      }
                                      setVsetkyUdaje({
                                        ...vsetkyUdaje,
                                        vybavenie: {
                                          ...vsetkyUdaje.vybavenie,
                                          rozsirene: zmena,
                                        },
                                      });
                                    }}
                                  ></input>
                                  <button
                                    hidden={
                                      vsetkyUdaje.vybavenie.rozsirene.length -
                                        1 ===
                                      i
                                        ? true
                                        : false
                                    }
                                    className="tlacitko-vymazat-male"
                                    style={{ marginLeft: "5px" }}
                                    onClick={(e) => {
                                      let zmena = [
                                        ...vsetkyUdaje.vybavenie.rozsirene,
                                      ];
                                      zmena = zmena.filter(
                                        (e, index) => i !== index
                                      );
                                      setVsetkyUdaje({
                                        ...vsetkyUdaje,
                                        vybavenie: {
                                          ...vsetkyUdaje.vybavenie,
                                          rozsirene: zmena,
                                        },
                                      });
                                    }}
                                  >
                                    x
                                  </button>
                                </div>
                              );
                            })}
                          </div>
                        )}
                      </div>
                    </div>

                    <div
                      style={{
                        marginTop: "30px",
                        display: "flex",
                        gap: "40px",
                        alignItems: "start",
                      }}
                    >
                      <FormControl>
                        <FormLabel id="demo-controlled-radio-buttons-group">
                          Povoliť mazanie hodnôt
                        </FormLabel>
                        <RadioGroup
                          aria-labelledby="demo-controlled-radio-buttons-group"
                          name="controlled-radio-buttons-group"
                          value={mazanie}
                          onChange={() => setMazanie(!mazanie)}
                        >
                          <FormControlLabel
                            value={true}
                            control={<Radio color="success" />}
                            label="Áno"
                          />
                          <FormControlLabel
                            value={false}
                            control={<Radio color="default" />}
                            label="Nie"
                          />
                        </RadioGroup>
                      </FormControl>

                      <FormControl>
                        <FormLabel id="demo-controlled-radio-buttons-group">
                          Povoliť mazanie a pridávanie stĺpcov
                        </FormLabel>
                        <RadioGroup
                          aria-labelledby="demo-controlled-radio-buttons-group"
                          name="controlled-radio-buttons-group"
                          value={stlpce}
                          onChange={() => setStlpce(!stlpce)}
                        >
                          <FormControlLabel
                            value={true}
                            control={<Radio color="success" />}
                            label="Áno"
                          />
                          <FormControlLabel
                            value={false}
                            control={<Radio color="default" />}
                            label="Nie"
                          />
                        </RadioGroup>
                      </FormControl>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={presuvanie}
                            onChange={() => {
                              setPresuvanie(!presuvanie);
                            }}
                          />
                        }
                        label="Povoliť presúvanie"
                      />
                    </div>
                  </div>

                  <img
                    className="hlavny_obrazok pravy_margin-konfi"
                    src={`https://konfigurator.regada.sk/obrazkyVsetky/servopohony/${vsetkyUdaje.kod}.png`}
                    alt="Nie je nahratý"
                  />
                </div>
                <div className="board lavy_margin-konfi">
                  <div className="container">
                    {classyTabulky.map((e, i) => {
                      return (
                        <EditTabulka
                          tabulka={vsetkyUdaje["tabulka" + (i + 1).toString()]}
                          cisloHlavnejTab={i + 1}
                          mazanie={mazanie}
                          stlpce={stlpce}
                          updateVsetkyUdaje={updateVsetkyUdaje}
                          classPreTabulka={e}
                          vsetkyUdaje={vsetkyUdaje}
                          presuvanie={presuvanie}
                        />
                      );
                    })}
                    {vsetkyUdaje.tabulka7 ? (
                      <div className="siedma_tabulka">
                        <div className="table-header">
                          {vsetkyUdaje.tabulka7.nazvy.map((nazov, i) => {
                            return (
                              <input
                                key={"6" + i}
                                className="siedma_item-edit"
                                value={nazov}
                                onChange={(e) => {
                                  let novaNazovHodnota =
                                    vsetkyUdaje.tabulka7.nazvy;

                                  novaNazovHodnota[i] = e.target.value;
                                  setVsetkyUdaje({
                                    ...vsetkyUdaje,
                                    tabulka7: {
                                      ...vsetkyUdaje.tabulka7,
                                      nazvy: novaNazovHodnota,
                                    },
                                  });
                                }}
                              ></input>
                            );
                          })}
                          <div className="siedma_item">↓</div>
                          <div className="siedma_item">↓</div>
                        </div>
                        <div className="table-content">
                          {" "}
                          {vsetkyUdaje.tabulka7.hodnoty.map(
                            (hodnota, indexRiadku) => {
                              let indexy = 8;
                              // let pismenko = hodnota[0];
                              return (
                                <div
                                  key={indexy.toString() + indexRiadku}
                                  className="nestisknute table-row-siedma-edit"
                                >
                                  {hodnota.map(
                                    (jednaHodnota, indexJednejHodnoty) => {
                                      return (
                                        <input
                                          className="siedma_data-edit"
                                          value={jednaHodnota}
                                          onChange={(e) => {
                                            let novaHodnota =
                                              vsetkyUdaje.tabulka7.hodnoty;

                                            novaHodnota[indexRiadku][
                                              indexJednejHodnoty
                                            ] = e.target.value;
                                            setVsetkyUdaje({
                                              ...vsetkyUdaje,
                                              tabulka7: {
                                                ...vsetkyUdaje.tabulka7,
                                                hodnoty: novaHodnota,
                                              },
                                            });
                                          }}
                                        ></input>
                                      );
                                    }
                                  )}
                                </div>
                              );
                            }
                          )}
                          <h2>Pridávanie kombinácii</h2>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: "2px",
                              marginTop: "14px",
                              marginBottom: "14px",
                            }}
                          >
                            <TextField
                              id="outlined-basic"
                              label="Kód"
                              variant="outlined"
                              type="text"
                              onChange={(e) =>
                                setKombinaciaKey(e.target.value.toUpperCase())
                              }
                              value={kombinaciaKey}
                              size="small"
                              style={{
                                width: "80px",
                              }}
                              inputProps={{ maxLength: 4 }}
                            />
                            <div> =</div>
                            <TextField
                              id="outlined-basic"
                              label="Kombi."
                              variant="outlined"
                              type="text"
                              onChange={(e) =>
                                setKombinaciaValue(e.target.value.toUpperCase())
                              }
                              value={kombinaciaValue}
                              size="small"
                              style={{
                                width: "80px",
                              }}
                              inputProps={{ maxLength: 4 }}
                            />
                            <Button
                              style={{ marginLeft: "10px" }}
                              variant="contained"
                              onClick={() => {
                                if (kombinaciaKey.length > 1) {
                                  const noveKombinacie = Object.entries({
                                    ...vsetkyUdaje.tabulka7.kombinacie,
                                    [kombinaciaKey]: kombinaciaValue,
                                  });

                                  noveKombinacie.sort((a, b) => a[1] - b[1]);

                                  const sortedKombinacie =
                                    Object.fromEntries(noveKombinacie);

                                  setVsetkyUdaje({
                                    ...vsetkyUdaje,
                                    tabulka7: {
                                      ...vsetkyUdaje.tabulka7,
                                      kombinacie: sortedKombinacie,
                                    },
                                  });
                                  setKombinaciaKey("");
                                  setKombinaciaValue("");
                                }
                              }}
                            >
                              Pridať
                            </Button>
                          </div>
                          <div className="dov_kombinacie ">
                            <div id="kombinacie">
                              Dovolené kombinácie a kódy:{" "}
                              <Stack
                                direction="row"
                                spacing={1}
                                style={{ marginTop: "5px" }}
                                flexWrap="wrap"
                                useFlexGap
                              >
                                {vsetkyUdaje.tabulka7.kombinacie
                                  ? Object.keys(
                                      vsetkyUdaje.tabulka7.kombinacie
                                    ).map((elem, i) => {
                                      if (elem.length > 1) {
                                        return (
                                          <Chip
                                            variant="outlined"
                                            label={
                                              elem.split("").join("+") +
                                              "=" +
                                              vsetkyUdaje.tabulka7.kombinacie[
                                                elem
                                              ]
                                            }
                                            onDelete={() => {
                                              deleteKombinacia(elem);
                                            }}
                                          />
                                        );
                                      }
                                      return null;
                                    })
                                  : ""}
                              </Stack>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </>

              <div className="fixed-bottom-right">
                <div className="tlacitko" onClick={() => setShowModal(true)}>
                  Upraviť
                </div>
              </div>
            </div>
          </div>

          {showModal && (
            <OknoEdit
              setShowModal={setShowModal}
              vsetkyUdaje={vsetkyUdaje}
              setNacitava={setNacitava}
              handlePopUp={handlePopUp}
              nadpisForm="Chcete naozaj upraviť servopohon?"
              popisForm="Editujete servopohon:"
            />
          )}
        </>
      )}

      {!vsetkyUdaje && <Loading />}
      {nacitava && <Loading />}
    </>
  );
};

export default Editovanie;
