import { useState, useEffect } from "react";
import { FaSearch } from "react-icons/fa";
import sipka_find from "../images/icons8-arrow1.svg";
import axios from "axios";
import Nav from "../components/Nav";
import Loading from "../components/LoadingScreen";
import Formular from "../components/Formular";
import DesifrovacTabulka from "../components/DesifrovacComp/DesifrovacTabulka";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import HornyIndex from "../components/HornyIndex";
import { Tooltip } from "@mui/material";

const SearchEngine = () => {
  // const serverAdresa = "https://prufen-0794a563f6b8.herokuapp.com/";
  const serverAdresa = "https://vps.regada.sk/";

  // const serverAdresa = "http://localhost:8000/";

  const [pdfUrl, setPdfUrl] = useState(null);
  const [pdfUrlSchemy, setPdfUrlSchemy] = useState(null);
  const [pdfUrlNacrtky, setPdfUrlpdfUrlNacrtky] = useState(null);

  const [showModal, setShowModal] = useState(false);

  const [najdeneZakaznicke, setNajdeneZakaznicke] = useState(false);

  const [input, setInput] = useState("");
  const [vsetkyUdaje, setVsetkyUdaje] = useState(null);
  const [error, setError] = useState(null);
  const [stlaceneCele, setStlaceneCele] = useState(null);
  const [slovnik, setSlovnik] = useState(null);
  const [nacitavanie, setNacitavanie] = useState(false);
  const [typove, setTypove] = useState("");
  const [celyRiadok, setCelyRiadok] = useState([[], [], [], [], [], [], []]);
  // const [zakaznicke, setZakaznicke] = useState(["", "-", "", "", "", "", ""]);
  const [zakaznickeVyhotovenieNajdene, setZakaznickeVyhotovenieNajdene] =
    useState("");

  const [ktoryNazov, setKtoryNazov] = useState([[], "-", [], [], [], [], []]);
  const [objednavacieCisla, setObjednavacieCisla] = useState("");
  const [nazov, setNazov] = useState("");
  const [rozsireneVsetky, setRozsireneVsetky] = useState([]);

  const [najdenePoznamky, setNajdenePoznamky] = useState([
    [],
    [],
    [],
    [],
    [],
    [],
    [],
    [],
    [],
  ]);

  const timeElapsed = Date.now();
  const today = new Date(timeElapsed);
  const formattedDate = `${today.getDate()}.${
    today.getMonth() + 1
  }.${today.getFullYear()}`;

  let kombinacie = {};
  let kombinacieSpolu = {};
  // let kombinacieCisla = [];
  // let kombinaciePismena = [];
  let kombinacieZakaznicke = [];

  const classyTabulky = [
    "prva",
    "druha",
    "tretia",
    "stvrta",
    "piata",
    "siesta",
  ];

  const [poznamka, setPoznamka] = useState({});

  const fetchPDF = async () => {
    try {
      const response = await axios.post(
        `${serverAdresa}generatePDF`,
        {
          nazov,
          formattedDate,
          typove,
          ktoryNazov,
          celyRiadok,
          stlaceneCele,
          rozsireneVsetky,
          zakaznickeVyhotovenieNajdene,
          najdenePoznamky,
          poznamka,
          slovnik,
          selectedLanguage,
        },
        {
          responseType: "blob", // Specify the response type
        }
      );

      const pdfUrl = URL.createObjectURL(
        new Blob([response.data], { type: "application/pdf" })
      );
      setPdfUrl(pdfUrl);
    } catch (error) {
      console.error("Error fetching PDF:", error);
    }
  };

  const handleDownloadPDF = async () => {
    setNacitavanie(true);
    await fetchPDF(); // Fetch the PDF from the backend
    setNacitavanie(false);
  };

  useEffect(() => {
    if (pdfUrl) {
      const link = document.createElement("a");
      link.href = pdfUrl;
      // link.download = today.toDateString(); // Set the suggested filename
      link.download = "konfiguracia_regada";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }, [pdfUrl]);

  const [selectedLanguage, setSelectedLanguage] = useState("SK");

  const handleLanguageChange = (language) => {
    setSelectedLanguage(language);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setNacitavanie(true);
    setNajdeneZakaznicke(false);
    setZakaznickeVyhotovenieNajdene("");
    setRozsireneVsetky([]);

    // od seknut po /
    // dat prec medzery
    // od / po prvu medzeru co najdem - mam objednavaci

    // po lomitko
    let prvaCast = input.slice(0, input.indexOf("/")).toUpperCase();
    prvaCast = prvaCast.replace(/\s/g, "");
    // od lomitka
    let druhaCast = input
      .slice(input.indexOf("/") + 1)
      .replace(/\s/g, "")
      .toUpperCase();

    const bodka = prvaCast.indexOf(".");
    const typove = prvaCast.slice(0, bodka);
    setTypove(typove);
    // odstrihnut typove
    prvaCast = prvaCast.slice(bodka + 1);

    // vsestko bez typoveho

    let udajeDoTabulky = null;
    try {
      const response = await axios.get(`${serverAdresa}standard`, {
        params: { typove },
      });
      const response2 = await axios.get(`${serverAdresa}vseobPoznamky`);

      if (typeof response.data === "string") {
        udajeDoTabulky = null;
        setVsetkyUdaje(null);
        if (selectedLanguage === "SK") {
          setError("Typové číslo " + typove + " nebolo nájdené.");
        } else if (selectedLanguage === "EN") {
          setError("Type number " + typove + " wasn't found.");
        } else {
          setError("Введите номер " + typove + " не был найден.");
        }
      } else {
        udajeDoTabulky = response.data;
        setVsetkyUdaje(response.data);
        setNazov(response.data.nazov);
        if (response.data.poznamka) {
          for (const key in response2.data[0]) {
            if (!(key in response.data.poznamka)) {
              response.data.poznamka[key] = response2.data[0][key];
            }
          }

          setPoznamka(response.data.poznamka);
        } else {
          setPoznamka(response2.data[0]);
        }
        setError(null);
      }
    } catch (err) {
      // setError(err.response.data);
      console.log(err.response.data);
    }

    if (udajeDoTabulky) {
      kombinacieData(udajeDoTabulky);

      let pismenka = getKeyByValue(kombinacieSpolu, druhaCast);

      let dokoncenyObjednavaci = "";

      // bezny konfig
      if (pismenka) {
        let rozsireneVsetkyDocasne = [];
        dokoncenyObjednavaci = [
          ...prvaCast.split(""),
          "/",
          ...druhaCast.split(""),
        ];
        // SIEDMA TABULKA VSETKY HODNOTY
        udajeDoTabulky.tabulka7.hodnoty.forEach((e) => {
          if (pismenka.includes(e[0])) {
            rozsireneVsetkyDocasne.push(e);
          }
        });

        setRozsireneVsetky(rozsireneVsetkyDocasne);
        setObjednavacieCisla(druhaCast.split(""));
      }
      // zakaznicke ked su
      else if (kombinacieZakaznicke.includes(druhaCast)) {
        dokoncenyObjednavaci = [
          ...prvaCast.split(""),
          "/",
          ...druhaCast.split(""),
        ];

        setZakaznickeVyhotovenieNajdene(
          udajeDoTabulky.zakaznicke_vyhotovenia[druhaCast]
        );
        setObjednavacieCisla(druhaCast.split(""));
      }
      // nenajdena koncovka
      else {
        dokoncenyObjednavaci = [...prvaCast.split(""), "/", ...["-", "-"]];
        if (druhaCast !== "") {
          setError("Koncovka " + druhaCast + " nebola nájdená");
        }

        setRozsireneVsetky([]);
      }

      setStlaceneCele(dokoncenyObjednavaci);
      // cisla objednavacie

      findValues(prvaCast.split(""), udajeDoTabulky, druhaCast);
    }

    setNacitavanie(false);
  };

  const findValues = (dokoncenyObjednavaci, udajeDoTabulky, druhaCast) => {
    let riadok = [[], [], [], [], [], [], []];
    // let zakaznickeBase = ["", "-", "", "", "", "", ""];
    let nazvyBase = ["", "-", "", "", "", "", ""];

    if (udajeDoTabulky) {
      dokoncenyObjednavaci.forEach((e, i) => {
        let indexPreTabulky = i === 0 ? 1 : i;
        if (e.toUpperCase() !== "X" && e !== "-") {
          udajeDoTabulky["tabulka" + indexPreTabulky].forEach((element, i1) => {
            element.hodnoty.forEach((element1, i2) => {
              if (element1[element1.length - 1] === e) {
                riadok[i] = element1;
                nazvyBase[i] =
                  udajeDoTabulky["tabulka" + indexPreTabulky][i1].nazvy;
              }
            });
          });
        } else if (e.toUpperCase() === "X") {
          nazvyBase[i] = udajeDoTabulky["tabulka" + indexPreTabulky][0].nazvy;
          setNajdeneZakaznicke(true);
        }
      });
      setKtoryNazov(nazvyBase);
      // setZakaznicke(zakaznickeBase);
      setCelyRiadok(riadok);

      najdiPoznamky(nazvyBase, riadok);
    }
  };

  const najdiPoznamky = (nazvyBase, riadok) => {
    let terazNajdenePoznamky = [[], [], [], [], [], [], [], [], []];

    nazvyBase.forEach((nazvyRiadok, indexNazvyTabulka) => {
      if (Array.isArray(nazvyRiadok)) {
        nazvyRiadok.forEach((jednaCast) => {
          if (Array.isArray(jednaCast)) {
            jednaCast.forEach((group) => {
              group.forEach((jednaCast) => {
                if (jednaCast.includes(")")) {
                  terazNajdenePoznamky[8] = [
                    ...terazNajdenePoznamky[8],
                    ...jednaCast
                      .split(" ")
                      .filter((e) => e.includes(")") & !isNaN(e.slice(0, -1))),
                  ];
                }
              });
            });
          } else {
            if (jednaCast.includes(")")) {
              terazNajdenePoznamky[8] = [
                ...terazNajdenePoznamky[8],
                ...jednaCast
                  .split(" ")
                  .filter((e) => e.includes(")") & !isNaN(e.slice(0, -1))),
              ];
            }
          }
        });
      }
    });

    riadok.forEach((jedenRiadok, indexyRiadkov) => {
      if (Array.isArray(jedenRiadok)) {
        jedenRiadok.forEach((hodnota) => {
          if (hodnota.includes(")")) {
            terazNajdenePoznamky[indexyRiadkov] = [
              ...terazNajdenePoznamky[indexyRiadkov],
              ...hodnota
                .split(" ")
                .filter((e) => e.includes(")") & !isNaN(e.slice(0, -1))),
            ];
          }
        });
      }
    });
    setNajdenePoznamky(terazNajdenePoznamky);
  };

  const handleChange = (e) => {
    setInput(e.target.value);
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      input.includes("/")
        ? handleSubmit(event)
        : setError("Lomítko chýba v objednávacom kóde.");
    }
  };

  const kombinacieData = (vsetkyUdaje) => {
    for (let i = 0; i < vsetkyUdaje.tabulka7.hodnoty.length; i++) {
      let jeden_riadok = vsetkyUdaje.tabulka7.hodnoty[i];

      kombinacie[jeden_riadok[0]] =
        jeden_riadok[jeden_riadok.length - 2] +
        jeden_riadok[jeden_riadok.length - 1];
    }

    // PRIDANE AJ ZAKAZNICKE
    kombinacieSpolu = {
      ...kombinacie,
      ...vsetkyUdaje.tabulka7.kombinacie,
    };

    if (vsetkyUdaje.zakaznicke_vyhotovenia) {
      kombinacieZakaznicke = Object.keys(vsetkyUdaje.zakaznicke_vyhotovenia);
    }
  };

  async function getSlovnik() {
    try {
      // vseobecnySlovnik
      const response1 = await axios.get(`${serverAdresa}slovnikVseob`);

      // poznamkySlovnik
      const response2 = await axios.get(`${serverAdresa}slovnikPoznamky`);

      setSlovnik({ ...response1.data, ...response2.data });
    } catch (e) {
      console.log(e);
    }
  }

  function getKeyByValue(object, value) {
    return Object.keys(object).find((key) => object[key] === value);
  }

  useEffect(() => {
    getSlovnik();
  }, []);

  const fetchPDFSpecial = async (typePdf) => {
    const prva = "Z1a";
    const druha = "Z11a";
    const tretia = "Z269r";
    try {
      const response = await axios.get(`${serverAdresa}${typePdf}`, {
        responseType: "blob",
        params: {
          typove,
          prva,
          druha,
          tretia,
        },
      });

      const pdfUrl = URL.createObjectURL(
        new Blob([response.data], { type: "application/pdf" })
      );
      if (typePdf === "generateSchemy") {
        setPdfUrlSchemy(pdfUrl);
      } else if (typePdf === "generateNacrtky") {
        setPdfUrlpdfUrlNacrtky(pdfUrl);
      }
    } catch (error) {
      console.error("Error fetching PDF:", error);
    }
  };

  useEffect(() => {
    if (pdfUrlSchemy) {
      const link = document.createElement("a");
      link.href = pdfUrlSchemy;
      // link.download = today.toDateString(); // Set the suggested filename
      link.download = "schemy_regada_" + typove + "_" + vsetkyUdaje.nazov;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }, [pdfUrlSchemy]);

  useEffect(() => {
    if (pdfUrlNacrtky) {
      const link = document.createElement("a");
      link.href = pdfUrlNacrtky;
      // link.download = today.toDateString(); // Set the suggested filename
      link.download = "nacrty_regada_" + typove + "_" + vsetkyUdaje.nazov;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }, [pdfUrlNacrtky]);

  const handleDownloadPDFSpecial = async (ktoryPdf) => {
    setNacitavanie(true);
    await fetchPDFSpecial(ktoryPdf); // Fetch the PDF from the backend
    setNacitavanie(false);
  };

  return (
    <div>
      {nacitavanie && <Loading />}

      {!nacitavanie && (
        <>
          <div className={showModal ? "zoslabenie" : ""}>
            <Nav
              onSelectLanguage={handleLanguageChange}
              defaultLang={selectedLanguage}
              landingPage={true}
            />

            <div className="desifrovac">
              <div>
                <div onSubmit={handleSubmit} className="input-wrapper">
                  <FaSearch id="search-icon" />

                  <input
                    className="cislo_objednavacie"
                    placeholder="Objednávaci kód..."
                    onChange={handleChange}
                    onKeyDown={handleKeyDown}
                    value={input}
                    maxLength={25}
                  />
                  <img
                    onClick={(e) =>
                      input.includes("/")
                        ? handleSubmit(e)
                        : setError("Lomítko chýba v objednávacom kóde.")
                    }
                    src={sipka_find}
                    alt="sipka"
                    className="checkmark"
                  />
                </div>

                {error && (
                  <Stack
                    sx={{ width: "400px", margin: "auto", marginTop: "20px" }}
                    spacing={2}
                  >
                    <Alert
                      severity="error"
                      onClose={() => {
                        setError(null);
                      }}
                    >
                      {error}
                    </Alert>
                  </Stack>
                )}
                {/* <p>062.5-7CXYK/PRUA</p>
                <p>260.6-L1ABT/01</p>
                <p>062.2-RR5VK/06</p> */}

                {/* <p>260.X-L1ABT/AI</p>
                <p>260.0-L1AXT/AH</p>
                <p>062.3-LM1BB/03</p>
                <p>260.0-L1ABT/04</p>
                <p>093.3-5Q5SN/09</p>
                <p>062.5-7CXYK/PRUA</p>
                <p>283.2-X3LSG/PRU</p> */}

                {vsetkyUdaje && slovnik && (
                  <div className="obalena_tab1">
                    <div className="dole_desifrovac">
                      <div
                        className="nazov_obrazok"
                        style={{ marginBottom: "10px" }}
                      >
                        <div>
                          <h1 id="nazov">{vsetkyUdaje.nazov}</h1>
                          {najdeneZakaznicke && (
                            <h2 className="zakaznicke_nazov_mobile">
                              Zákaznícke vyhotovenie
                            </h2>
                          )}
                        </div>
                        <img
                          className="hlavny_obrazok"
                          src={`https://konfigurator.regada.sk/obrazkyVsetky/servopohony/${vsetkyUdaje.kod}.png`}
                          alt={vsetkyUdaje.nazov}
                        />
                      </div>
                      <div className="board">
                        <div>
                          <div id="objednavaci_kod">
                            <div
                              className="objednavaci_riadok"
                              id="order_code_label"
                            >
                              {selectedLanguage === "EN"
                                ? "Order code"
                                : selectedLanguage === "RU"
                                ? "Марка исполнения"
                                : "Objednávací kód"}
                            </div>
                            <div className="prava_strana">
                              <div id="kodmaly" className="objednavaci_riadok">
                                {typove.length > 3
                                  ? typove.slice(0, 2) + " " + typove.slice(2)
                                  : typove}
                                .
                              </div>
                              <div className="kod_cisla">
                                {" "}
                                {stlaceneCele.map((ciselo) => {
                                  return (
                                    <div className="objednavaci_riadok cislo">
                                      {ciselo}
                                    </div>
                                  );
                                })}
                              </div>
                            </div>
                          </div>

                          <div className="container">
                            {classyTabulky.map((e, i) => {
                              return (
                                <DesifrovacTabulka
                                  stlacene={stlaceneCele}
                                  ktoryNazov={ktoryNazov}
                                  celyRiadok={celyRiadok}
                                  vsetkyUdaje={vsetkyUdaje}
                                  poznamka={poznamka}
                                  slovnik={slovnik}
                                  selectedLanguage={selectedLanguage}
                                  cisloHlavnejTab={i === 0 ? 0 : i + 1}
                                  classPreTabulka={e}
                                />
                              );
                            })}
                            {objednavacieCisla &&
                              rozsireneVsetky.length > 0 && (
                                <div className="siedma_tabulka">
                                  <div className="table-header">
                                    {vsetkyUdaje.tabulka7.nazvy.map(
                                      (nazov, i) => {
                                        return (
                                          <div
                                            key={"6" + i}
                                            className="siedma_item"
                                          >
                                            {slovnik.hasOwnProperty(nazov)
                                              ? selectedLanguage === "EN"
                                                ? slovnik[nazov][0]
                                                : selectedLanguage === "RU"
                                                ? slovnik[nazov][1]
                                                : nazov
                                              : nazov}
                                          </div>
                                        );
                                      }
                                    )}
                                    <div className="siedma_item">↓</div>
                                    <div className="siedma_item">↓</div>
                                  </div>

                                  <div className="table-content">
                                    {rozsireneVsetky.map(
                                      (siedmaRiadok, siedmaIndexRiadok) => {
                                        return (
                                          <div className="table-row-siedma">
                                            {siedmaRiadok.map(
                                              (hodnotaRiadok, i) => {
                                                if (
                                                  hodnotaRiadok.includes(")")
                                                ) {
                                                  let rozdelene =
                                                    hodnotaRiadok.split(" ");
                                                  let poznamky =
                                                    rozdelene.filter(
                                                      (e) =>
                                                        e.includes(")") &
                                                        !isNaN(e.slice(0, -1))
                                                    );
                                                  let text_hodnota = rozdelene
                                                    .filter(
                                                      (e) =>
                                                        !poznamky.includes(e)
                                                    )
                                                    .join(" ");

                                                  return (
                                                    <div
                                                      className="siedma_data"
                                                      style={{
                                                        display: "flex",
                                                        justifyContent:
                                                          "center",
                                                        gap: "5px",
                                                      }}
                                                    >
                                                      <div>
                                                        {slovnik ? (
                                                          slovnik.hasOwnProperty(
                                                            text_hodnota
                                                          ) ? (
                                                            selectedLanguage ===
                                                            "EN" ? (
                                                              <HornyIndex
                                                                hodnotaRiadok={
                                                                  slovnik[
                                                                    text_hodnota
                                                                  ][0]
                                                                }
                                                              />
                                                            ) : selectedLanguage ===
                                                              "RU" ? (
                                                              <HornyIndex
                                                                hodnotaRiadok={
                                                                  slovnik[
                                                                    text_hodnota
                                                                  ][1]
                                                                }
                                                              />
                                                            ) : (
                                                              <HornyIndex
                                                                hodnotaRiadok={
                                                                  text_hodnota
                                                                }
                                                              />
                                                            )
                                                          ) : (
                                                            <HornyIndex
                                                              hodnotaRiadok={
                                                                text_hodnota
                                                              }
                                                            />
                                                          )
                                                        ) : (
                                                          <HornyIndex
                                                            hodnotaRiadok={
                                                              text_hodnota
                                                            }
                                                          />
                                                        )}
                                                      </div>
                                                      {poznamky.map((e, i) => {
                                                        let poznamkaUpravena =
                                                          "";
                                                        if (poznamka) {
                                                          if (
                                                            poznamka[
                                                              e.slice(0, -1)
                                                            ]
                                                          ) {
                                                            poznamkaUpravena =
                                                              poznamka[
                                                                e.slice(0, -1)
                                                              ];
                                                          } else {
                                                            poznamkaUpravena =
                                                              "Poznámka nebola nájdena";
                                                          }
                                                        } else {
                                                          poznamkaUpravena =
                                                            "Poznámka nebola nájdena";
                                                        }
                                                        return (
                                                          <Tooltip
                                                            title={
                                                              slovnik
                                                                ? slovnik.hasOwnProperty(
                                                                    poznamkaUpravena
                                                                  )
                                                                  ? selectedLanguage ===
                                                                    "EN"
                                                                    ? slovnik[
                                                                        poznamkaUpravena
                                                                      ][0]
                                                                    : selectedLanguage ===
                                                                      "RU"
                                                                    ? slovnik[
                                                                        poznamkaUpravena
                                                                      ][1]
                                                                    : poznamkaUpravena
                                                                  : poznamkaUpravena
                                                                : poznamkaUpravena
                                                            }
                                                          >
                                                            <div className="tooltipExtra">
                                                              {e}
                                                            </div>
                                                          </Tooltip>
                                                        );
                                                      })}
                                                    </div>
                                                  );
                                                }
                                                return (
                                                  <div className="siedma_data">
                                                    {/* pre -1 */}
                                                    {slovnik ? (
                                                      slovnik.hasOwnProperty(
                                                        hodnotaRiadok
                                                      ) ? (
                                                        selectedLanguage ===
                                                        "EN" ? (
                                                          <HornyIndex
                                                            hodnotaRiadok={
                                                              slovnik[
                                                                hodnotaRiadok
                                                              ][0]
                                                            }
                                                          />
                                                        ) : selectedLanguage ===
                                                          "RU" ? (
                                                          <HornyIndex
                                                            hodnotaRiadok={
                                                              slovnik[
                                                                hodnotaRiadok
                                                              ][1]
                                                            }
                                                          />
                                                        ) : (
                                                          <HornyIndex
                                                            hodnotaRiadok={
                                                              hodnotaRiadok
                                                            }
                                                          />
                                                        )
                                                      ) : (
                                                        <HornyIndex
                                                          hodnotaRiadok={
                                                            hodnotaRiadok
                                                          }
                                                        />
                                                      )
                                                    ) : (
                                                      <HornyIndex
                                                        hodnotaRiadok={
                                                          hodnotaRiadok
                                                        }
                                                      />
                                                    )}
                                                  </div>
                                                );
                                              }
                                            )}
                                          </div>
                                        );
                                      }
                                    )}
                                  </div>
                                </div>
                              )}

                            {zakaznickeVyhotovenieNajdene.length > 0 && (
                              <>
                                <h2 style={{ margin: "4px 0 12px 0" }}>
                                  Popis pre zákaznícke vyhotovenie{" "}
                                  {objednavacieCisla}:
                                </h2>
                                <div
                                  className="table-row"
                                  style={{
                                    flexDirection: "column",
                                    alignItems: "start",
                                    gap: "4px",
                                  }}
                                >
                                  {zakaznickeVyhotovenieNajdene.map(
                                    (kazdaTabulka) => (
                                      <div style={{ marginLeft: "7px" }}>
                                        {kazdaTabulka}
                                      </div>
                                    )
                                  )}
                                </div>
                              </>
                            )}
                          </div>
                        </div>
                        <div className="desifrovac_tlacidla">
                          {/* <div
                            className="tlacitko"
                            onClick={() =>
                              handleDownloadPDFSpecial("generateSchemy")
                            }
                          >
                            {selectedLanguage === "EN"
                              ? "Diagrams"
                              : selectedLanguage === "RU"
                              ? "Схемы"
                              : "Schémy"}
                          </div> */}
                          {/* <div
                            className="tlacitko"
                            onClick={() =>
                              handleDownloadPDFSpecial("generateNacrtky")
                            }
                          >
                            {selectedLanguage === "EN"
                              ? "Drawings"
                              : selectedLanguage === "RU"
                              ? "Эскиз"
                              : "Rozm. Náčrt"}
                          </div> */}

                          <div className="tlacitko" onClick={handleDownloadPDF}>
                            {selectedLanguage === "EN"
                              ? "Save PDF"
                              : selectedLanguage === "RU"
                              ? "Сохранить PDF"
                              : "Uložiť PDF"}
                          </div>
                          {!najdeneZakaznicke && (
                            <div
                              className="tlacitko"
                              onClick={() => setShowModal(true)}
                            >
                              {selectedLanguage === "EN"
                                ? "Send a REQUEST"
                                : selectedLanguage === "RU"
                                ? "Отправить ЗАПРОС"
                                : "Poslať DOPYT"}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          {showModal && (
            <Formular
              setShowModal={setShowModal}
              vsetkyUdaje={vsetkyUdaje}
              stlaceneCele={stlaceneCele}
              typove={typove}
              ktoryNazov={ktoryNazov}
              celyRiadok={celyRiadok}
              rozsireneVsetky={rozsireneVsetky}
              slovnik={slovnik}
              selectedLanguage={selectedLanguage}
              poznamka={poznamka}
              najdenePoznamky={najdenePoznamky}
              zakaznickeVyhotovenieNajdene={zakaznickeVyhotovenieNajdene}
            />
          )}
        </>
      )}
    </div>
  );
};

export default SearchEngine;
