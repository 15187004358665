import Nazvy from "../Nazvy";
import Hodnota from "../Hodnota";

const DesifrovacTabulka = ({
  stlacene,
  ktoryNazov,
  celyRiadok,
  vsetkyUdaje,
  poznamka,
  slovnik,
  selectedLanguage,
  cisloHlavnejTab,
  classPreTabulka,
}) => {
  function countConsecutiveKrat(arr) {
    let count = 0;

    for (let i = 0; i < arr.length; i++) {
      if (arr[i] === "*") {
        count++;
      } else {
        break; // exit the loop when a different element is encountered
      }
    }

    return count + 1;
  }
  return (
    <div>
      {stlacene[cisloHlavnejTab].toLowerCase() !== "x" && (
        <div
          id={classPreTabulka + "0"}
          className={
            stlacene.slice(stlacene.indexOf("/") + 1).length === 3
              ? classPreTabulka + "_tabulka-3"
              : stlacene.slice(stlacene.indexOf("/") + 1).length === 4
              ? classPreTabulka + "_tabulka-4"
              : classPreTabulka + "_tabulka"
          }
        >
          {/* NAZVY 2X lebo ked neni najdene tak aby dalo default */}{" "}
          {celyRiadok[cisloHlavnejTab].length > 0 ? (
            <>
              <Nazvy
                tabulkaIteracia={{ nazvy: ktoryNazov[cisloHlavnejTab] }}
                poznamka={poznamka}
                prekladac={slovnik}
                jazyk={selectedLanguage}
              />
              <div className="table-content">
                <div className="table-row">
                  {celyRiadok[cisloHlavnejTab].map(
                    (hodnotaRiadok, indexHodnoty) => {
                      if (
                        celyRiadok[cisloHlavnejTab].length - 1 !==
                          indexHodnoty &&
                        indexHodnoty !== 0
                      ) {
                        // dalsia hodnota ma * vypocitat flex
                        if (
                          celyRiadok[cisloHlavnejTab][indexHodnoty + 1] === "*"
                        ) {
                          const velkostFlexu = countConsecutiveKrat(
                            celyRiadok[cisloHlavnejTab].slice(indexHodnoty + 1)
                          ).toString();

                          return (
                            <Hodnota
                              hodnotaRiadok={hodnotaRiadok}
                              slovnik={slovnik}
                              selectedLanguage={selectedLanguage}
                              poznamka={poznamka}
                              velkostFlexu={velkostFlexu}
                            />
                          );
                        } else if (hodnotaRiadok !== "*") {
                          return (
                            <Hodnota
                              hodnotaRiadok={hodnotaRiadok}
                              slovnik={slovnik}
                              selectedLanguage={selectedLanguage}
                              poznamka={poznamka}
                            />
                          );
                        } else return null;
                      } else if (hodnotaRiadok !== "*") {
                        return (
                          <Hodnota
                            hodnotaRiadok={hodnotaRiadok}
                            slovnik={slovnik}
                            selectedLanguage={selectedLanguage}
                            poznamka={poznamka}
                          />
                        );
                      } else return null;
                    }
                  )}
                </div>
              </div>
            </>
          ) : (
            <>
              <Nazvy
                tabulkaIteracia={{
                  nazvy:
                    vsetkyUdaje[
                      "tabulka" +
                        (cisloHlavnejTab === 0
                          ? "1"
                          : cisloHlavnejTab.toString())
                    ][0].nazvy,
                }}
                poznamka={poznamka}
                prekladac={slovnik}
                jazyk={selectedLanguage}
              />
              <div className="table-content">
                <div className="table-row-x">
                  <div className="table-data-zakaznicke">
                    <b>{stlacene[cisloHlavnejTab]}</b> {" nebolo nájdene."}{" "}
                  </div>
                  <div className="table-data-zakaznicke"></div>
                </div>
              </div>
            </>
          )}
        </div>
      )}

      {stlacene[cisloHlavnejTab] === "X" && (
        <div
          id={classPreTabulka + "0"}
          className={
            stlacene.slice(stlacene.indexOf("/") + 1).length === 3
              ? classPreTabulka + "_tabulka-3"
              : stlacene.slice(stlacene.indexOf("/") + 1).length === 4
              ? classPreTabulka + "_tabulka-4"
              : classPreTabulka + "_tabulka"
          }
        >
          <Nazvy
            tabulkaIteracia={
              vsetkyUdaje[
                "tabulka" +
                  (cisloHlavnejTab === 0 ? 1 : cisloHlavnejTab).toString()
              ][0]
            }
            prekladac={slovnik}
            jazyk={selectedLanguage}
          />
          <div className="table-content">
            <div className="table-row-x">
              <div className="table-data-zakaznicke">
                {selectedLanguage === "SK"
                  ? "Zákaznicke vyhotovenie, špecifikáciu nájdete nižšie."
                  : selectedLanguage === "EN"
                  ? "Customer version, specification can be found below."
                  : "Версия клиента, технические характеристики можно найти ниже."}
              </div>
              <div className="table-data-zakaznicke">X</div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default DesifrovacTabulka;
