import { useState, useEffect, useRef } from "react";
import axios from "axios";
import Loading from "../components/LoadingScreen";

import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";

import regada_picture from "../images/logoTYPOGRAPHIAwhite.svg";
import sipka from "../images/icons8-arrow.svg";
import { useLocation, useNavigate } from "react-router-dom";
import DeleteIcon from "@mui/icons-material/Delete";

import InfoIcon from "@mui/icons-material/Info";
import DocumentaciaDialog from "../components/DocumentaciaDialog";

import AlertDialogSlide from "../components/AlertDialogSlide";

const NahratObrazky = () => {
  // const serverAdresa = "https://vps.regada.sk/";
  const serverAdresa = "http://localhost:8000/";

  const location = useLocation();
  const navigate = useNavigate();

  let zaheslovane = location.state.zaheslovane;

  const [obrazok, setObrazok] = useState(null);

  const [open, setOpen] = useState(false);

  const [selectedFile, setSelectedFile] = useState(null);
  const [chyba, setChyba] = useState(null);

  const isFirstRun = useRef(true);

  const [images, setImages] = useState([]);
  const [vyhladavanie, setVyhladavanie] = useState("");
  const [nacitava, setNacitava] = useState(false);

  const [visibleItems, setVisibleItems] = useState(20);

  const [openDocumentacia, setOpenDocumentacia] = useState(false);

  const [prazdne, setPrazdne] = useState(false);

  const [zamrazeneTladidla, setZamrazeneTladidla] = useState(false);

  const [ktoreObrazky, setKtoreObrazky] = useState("");

  const loadImages = async () => {
    setZamrazeneTladidla(true);
    setPrazdne(false);
    setImages([]);
    try {
      const response = await axios.get(
        `${serverAdresa}getImages?ktoreObrazky=${ktoreObrazky}`
      );
      const newImages = response.data.imageNames;
      setImages((prevImages) => [...prevImages, ...newImages]);
    } catch (error) {
      setPrazdne(true);
    }

    setZamrazeneTladidla(false);
  };

  const zmenaObrazkov = (naKtoreSaMeni) => {
    if (!zamrazeneTladidla) {
      setPrazdne(false);
      setVisibleItems(20);
      setKtoreObrazky(naKtoreSaMeni);
      setVyhladavanie("");
      setObrazok(null);
    }
  };

  const handleFileChange = (e) => {
    setSelectedFile(e.target.files[0]);
  };

  const showMoreItems = () => {
    // Increase the number of visible items by 20
    setVisibleItems((prevVisibleItems) => prevVisibleItems + 20);
  };

  const fetchObrazok = async (vyhladavanie) => {
    try {
      const response = await axios.get(`${serverAdresa}najdiObrazok`, {
        params: { ktoreObrazky, vyhladavanie },
      });

      if (response.status === 200) {
        const imageNameWithExtension = response.data;
        setObrazok(
          `https://konfigurator.regada.sk/obrazkyVsetky/${ktoreObrazky}/${imageNameWithExtension}`
        );
        setChyba(null);
      } else {
        setChyba("Schéma nebola nájdena");
        // Handle other response statuses if needed
      }
    } catch (error) {
      setChyba("Nebolo nájdene.");
      console.error("Error fetching picture", error);
    }
  };

  const handleUpload = async () => {
    if (!selectedFile) {
      alert("Vyberte obrázok.");
      return;
    }

    setNacitava(true);

    const formData = new FormData();
    formData.append("image", selectedFile);
    formData.append("priecinok", `public/obrazkyVsetky/${ktoreObrazky}`);
    formData.append("useOriginalFileName", true);

    try {
      const response = await axios.post(`${serverAdresa}uploadFTP`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      if (response.status === 200) {
        console.log("File uploaded successfully:", response.data);
        setNacitava(false);
        loadImages();
        setOpen(true);
        setTimeout(() => {
          setOpen(false);
        }, 1500);
      }
    } catch (error) {
      console.error("Error uploading file:", error);
      setNacitava(false);
    }
  };

  const handleDeleteImage = async (image) => {
    setNacitava(true);
    try {
      // Send the image name to the backend for deletion
      await axios.delete(
        `${serverAdresa}delete-image?imageName=${image}&ktoreObrazky=${ktoreObrazky}`
      );

      // Remove the deleted image from the state
      loadImages();
      // setImages((prevImages) => prevImages.filter((img) => img !== image));
    } catch (error) {
      console.error("Error deleting image:", error);
    }
    setNacitava(false);
  };

  const handleDocumentacia = (e) => {
    setOpenDocumentacia(e);
  };

  useEffect(() => {
    if (isFirstRun.current) {
      // Skip the first run
      isFirstRun.current = false;
      return;
    }

    loadImages();
  }, [ktoreObrazky]);

  return (
    <>
      {!nacitava && (
        <div style={{ marginBottom: "160px" }}>
          <>
            <div id="headerup">
              {" "}
              <img
                id="back_header"
                src={sipka}
                alt="sipka"
                onClick={() =>
                  navigate("/menu", {
                    state: {
                      zaheslovane,
                    },
                  })
                }
                style={{ cursor: "pointer" }}
              ></img>
              <img
                src={regada_picture}
                id="typografia_header"
                alt="typography"
                onClick={() =>
                  navigate("/menu", {
                    state: {
                      zaheslovane,
                    },
                  })
                }
                style={{ cursor: "pointer" }}
              ></img>
              <div style={{ width: "60px" }}></div>
            </div>

            <DocumentaciaDialog
              openDocumentacia={openDocumentacia}
              handleDocumentacia={handleDocumentacia}
              ktoraDocumentacia={"obrazky"}
            />

            <AlertDialogSlide
              open={open}
              sprava="Obrázok bol úspešne pridaný."
            />

            <div
              style={{
                display: "flex",
                gap: "20px",
                justifyContent: "center",
                marginTop: "20px",
              }}
            >
              <div
                className={
                  ktoreObrazky === "servopohony"
                    ? "tlacitko_stlacene"
                    : "tlacitko"
                }
                onClick={() => {
                  zmenaObrazkov("servopohony");
                }}
              >
                Servopohony
              </div>

              <div
                className={
                  ktoreObrazky === "schemy" ? "tlacitko_stlacene" : "tlacitko"
                }
                onClick={() => {
                  zmenaObrazkov("schemy");
                }}
              >
                Schémy
              </div>

              <div
                className={
                  ktoreObrazky === "nacrty" ? "tlacitko_stlacene" : "tlacitko"
                }
                onClick={() => {
                  zmenaObrazkov("nacrty");
                }}
              >
                Rozm. náčrty
              </div>

              <div
                className={
                  ktoreObrazky === "ine" ? "tlacitko_stlacene" : "tlacitko"
                }
                onClick={() => {
                  zmenaObrazkov("ine");
                }}
              >
                Iné
              </div>
            </div>
            {ktoreObrazky !== "" && (
              <>
                <div
                  style={{
                    display: "flex",
                    alignItems: "start",
                    gap: "20px",
                    margin: "30px 10px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      marginLeft: "10px",
                      gap: "10px",
                    }}
                  >
                    <div style={{ display: "flex", gap: "40px" }}>
                      <div>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "end",
                            gap: "4px",
                          }}
                        >
                          <TextField
                            id="standard-basic"
                            label={
                              ktoreObrazky === "servopohony"
                                ? "Typové"
                                : ktoreObrazky === "schemy"
                                ? "Schémy"
                                : ktoreObrazky === "nacrty"
                                ? "Náčrty"
                                : "Iné"
                            }
                            variant="standard"
                            value={vyhladavanie}
                            onChange={(e) => {
                              setVyhladavanie(e.target.value.split(".")[0]);

                              setObrazok(null);
                            }}
                            style={{ width: "160px" }}
                          />
                          <InfoIcon
                            htmlColor="#005e9d"
                            style={{ cursor: "pointer", marginBottom: "2px" }}
                            onClick={() => setOpenDocumentacia(true)}
                          />
                        </div>

                        <div
                          className="tlacitko"
                          onClick={() => {
                            fetchObrazok(
                              vyhladavanie.charAt(0).toUpperCase() +
                                vyhladavanie.slice(1)
                            );
                          }}
                          style={{
                            width: "70px",
                            marginTop: "10px",
                            marginBottom: "20px",
                          }}
                        >
                          Vyhľadaj
                        </div>

                        {obrazok && (
                          <img
                            style={{ width: "100px" }}
                            src={obrazok}
                            alt="Nebolo nájdene"
                          />
                        )}
                        <div>{chyba}</div>
                      </div>

                      <div class="modal">
                        <div class="modal-header">
                          <div class="modal-logo">
                            <span class="logo-circle">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                xmlnsXlink="http://www.w3.org/1999/xlink"
                                width="512"
                                height="419.116"
                                viewBox="0 0 512 419.116"
                              >
                                <defs>
                                  <clipPath id="clip-folder-new">
                                    <rect width="512" height="419.116" />
                                  </clipPath>
                                </defs>
                                <g
                                  id="folder-new"
                                  clip-path="url(#clip-folder-new)"
                                >
                                  <path
                                    id="Union_1"
                                    data-name="Union 1"
                                    d="M16.991,419.116A16.989,16.989,0,0,1,0,402.125V16.991A16.989,16.989,0,0,1,16.991,0H146.124a17,17,0,0,1,10.342,3.513L227.217,57.77H437.805A16.989,16.989,0,0,1,454.8,74.761v53.244h40.213A16.992,16.992,0,0,1,511.6,148.657L454.966,405.222a17,17,0,0,1-16.6,13.332H410.053v.562ZM63.06,384.573H424.722L473.86,161.988H112.2Z"
                                    fill="var(--c-action-primary)"
                                    stroke=""
                                    stroke-width="1"
                                  />
                                </g>
                              </svg>
                            </span>
                          </div>
                          <button class="btn-close">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                            >
                              <path fill="none" d="M0 0h24v24H0V0z" />
                              <path
                                d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12 19 6.41z"
                                fill="var(--c-text-secondary)"
                              />
                            </svg>
                          </button>
                        </div>
                        <div class="modal-body">
                          <h2 class="modal-title">Nahrajte súbor</h2>
                          <p class="modal-description">Priložte súbor nižšie</p>
                          <input
                            type="file"
                            accept="image/*"
                            id="fileInput"
                            style={{ display: "none" }}
                            onChange={handleFileChange}
                          />
                          <button
                            className="upload-area"
                            onClick={() =>
                              document.getElementById("fileInput").click()
                            }
                          >
                            {" "}
                            <span className="upload-area-icon">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                xmlnsXlink="http://www.w3.org/1999/xlink"
                                width="340.531"
                                height="419.116"
                                viewBox="0 0 340.531 419.116"
                              >
                                <g
                                  id="files-new"
                                  clipPath="url(#clip-files-new)"
                                >
                                  <path
                                    id="Union_2"
                                    d="M-2904.708-8.885A39.292,39.292,0,0,1-2944-48.177V-388.708A39.292,39.292,0,0,1-2904.708-428h209.558a13.1,13.1,0,0,1,9.3,3.8l78.584,78.584a13.1,13.1,0,0,1,3.8,9.3V-48.177a39.292,39.292,0,0,1-39.292,39.292Zm-13.1-379.823V-48.177a13.1,13.1,0,0,0,13.1,13.1h261.947a13.1,13.1,0,0,0,13.1-13.1V-323.221h-52.39a26.2,26.2,0,0,1-26.194-26.195v-52.39h-196.46A13.1,13.1,0,0,0-2917.805-388.708Zm146.5,241.621a14.269,14.269,0,0,1-7.883-12.758v-19.113h-68.841c-7.869,0-7.87-47.619,0-47.619h68.842v-18.8a14.271,14.271,0,0,1,7.882-12.758,14.239,14.239,0,0,1,14.925,1.354l57.019,42.764c.242.185.328.485.555.671a13.9,13.9,0,0,1,2.751,3.292,14.57,14.57,0,0,1,.984,1.454,14.114,14.114,0,0,1,1.411,5.987,14.006,14.006,0,0,1-1.411,5.973,14.653,14.653,0,0,1-.984,1.468,13.9,13.9,0,0,1-2.751,3.293c-.228.2-.313.485-.555.671l-57.019,42.764a14.26,14.26,0,0,1-8.558,2.847A14.326,14.326,0,0,1-2771.3-147.087Z"
                                    transform="translate(2944 428)"
                                    fill="var(--c-action-primary)"
                                  />
                                </g>
                              </svg>
                            </span>
                            <span class="upload-area-title">
                              Vložte obrázok
                            </span>
                            <span class="upload-area-description">
                              Alebo kliknite <br />
                              <strong>sem</strong>
                            </span>
                            {selectedFile && (
                              <p>Zvolený obrázok: {selectedFile.name}</p>
                            )}
                          </button>
                        </div>
                        <div class="modal-footer">
                          <button class="btn-primary" onClick={handleUpload}>
                            Nahrať
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    style={{
                      width: "90vw",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      gap: "20px",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexWrap: "wrap",
                        justifyContent: "center",
                        gap: "14px",
                      }}
                    >
                      {images.slice(0, visibleItems).map((image, index) => (
                        <div
                          key={index}
                          style={
                            {
                              // border: "1px solid black",
                              // width:
                              //   ktoreObrazky === "ine"
                              //     ? "300px"
                              //     : ktoreObrazky === "schemy"
                              //     ? ""
                              //     : "1 00px",
                              // height:
                              //   ktoreObrazky === "schemy" ? "220px" : "160px",
                              // display: "flex",
                              // flexDirection: "column",
                              // marginBottom: "14px",
                              // justifyContent: "space-between",
                            }
                          }
                          className={
                            ktoreObrazky === "ine"
                              ? "jedenObrazokNahravanie ineObrazky"
                              : "jedenObrazokNahravanie"
                          }
                        >
                          <img
                            className="obrazkyNahravanie"
                            src={`https://konfigurator.regada.sk/obrazkyVsetky/${ktoreObrazky}/${image}`}
                            alt={image}
                          />
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                            }}
                          >
                            <div style={{ width: "100px" }}>{image}</div>

                            <DeleteIcon
                              onClick={() => handleDeleteImage(image)}
                              size="small"
                              style={{ cursor: "pointer" }}
                            ></DeleteIcon>
                          </div>
                        </div>
                      ))}
                    </div>

                    {prazdne && <div>Nič nebolo nájdene.</div>}
                    {images.length === 0 && !prazdne && (
                      <div class="loader"></div>
                    )}
                    {images.length !== 0 &&
                      !prazdne &&
                      images.length > visibleItems && (
                        <Button
                          onClick={showMoreItems}
                          variant="outlined"
                          size="small"
                          style={{ marginTop: "10px" }}
                        >
                          Načítať ďalsie
                        </Button>
                      )}
                  </div>
                </div>
              </>
            )}
          </>

          {/* {!(vsetkyServopohony && vsetkyUdaje) && <Loading />} */}
        </div>
      )}
      {nacitava && <Loading />}
    </>
  );
};

export default NahratObrazky;
