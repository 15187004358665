import { useState, useEffect, useRef } from "react";
import regada_picture from "../images/logoTYPOGRAPHIAwhite.svg";
import { useLocation } from "react-router-dom";
import axios from "axios";
import Loading from "../components/LoadingScreen";

const Final = () => {
  const serverAdresa = "https://vps.regada.sk/";
  // const serverAdresa = "http://localhost:8000/";

  const location = useLocation();
  let vsetkyUdaje = location.state.vsetkyUdaje;
  let stlaceneCele = location.state.stlaceneCele;
  let objednavacieCisla = location.state.objednavacieCisla;
  let typove = location.state.typove;
  let nazov = vsetkyUdaje.nazov;

  let meno = location.state.meno;
  let firma = location.state.firma;
  let email = location.state.email;
  let tel = location.state.tel;
  let oznacenie = location.state.oznacenie;
  let sprava = location.state.sprava;

  let ktoryNazov = location.state.ktoryNazov;
  let celyRiadok = location.state.celyRiadok;
  let rozsireneVsetky = location.state.rozsireneVsetky;

  let zakaznicke = location.state.zakaznicke;
  let zakaznickeVyhotovenieNajdene =
    location.state.zakaznickeVyhotovenieNajdene;

  let slovnik = location.state.slovnik;
  let selectedLanguage = location.state.selectedLanguage;

  let poznamka = location.state.poznamka;
  let najdenePoznamky = location.state.najdenePoznamky;

  let isFinal = true;

  const timeElapsed = Date.now();
  const today = new Date(timeElapsed);
  const formattedDate = `${today.getDate()}.${
    today.getMonth() + 1
  }.${today.getFullYear()}`;

  const [pdfUrl, setPdfUrl] = useState(null);
  const [nacitava, setNacitava] = useState(false);

  const prveSpustenie = useRef(false);

  const fetchPDF = async () => {
    setNacitava(true);
    try {
      const response = await axios.post(
        `${serverAdresa}generatePDF`,
        {
          isFinal,
          nazov,
          formattedDate,
          typove,
          ktoryNazov,
          celyRiadok,
          stlaceneCele,
          rozsireneVsetky,
          objednavacieCisla,
          zakaznicke,
          zakaznickeVyhotovenieNajdene,
          slovnik,
          selectedLanguage,
          meno,
          firma,
          email,
          tel,
          oznacenie,
          sprava,
          poznamka,
          najdenePoznamky,
        },
        {
          responseType: "blob", // Specify the response type
        }
      );

      const pdfUrl = URL.createObjectURL(
        new Blob([response.data], { type: "application/pdf" })
      );
      setPdfUrl(pdfUrl);
    } catch (error) {
      console.error("Error fetching PDF:", error);
    }
    setNacitava(false);
  };

  // pridatDoHistorie

  useEffect(() => {
    if (prveSpustenie.current === false) {
      // do fetch dat aj cislovanie
      fetchPDF();
      return () => (prveSpustenie.current = true);
    }
  }, []);

  return (
    <>
      {" "}
      {nacitava && <Loading />}
      {!nacitava && (
        <>
          <div id="headerup-final">
            {" "}
            <a
              id="typo_obal"
              href="https://www.regada.sk/elektricke-servopohony"
            >
              <img
                src={regada_picture}
                id="typografia_header"
                alt="typography"
              ></img>
            </a>
          </div>
          <div>
            <div className="final_info">
              {pdfUrl && (
                <iframe
                  title="PDF Preview"
                  className="pdf_final"
                  src={pdfUrl}
                  width="1020px"
                  height="1000px"
                  style={{ marginLeft: "30px" }}
                />
              )}
              <a href="https://www.regada.sk/elektricke-servopohony">
                <div className="tlacitko" style={{ margin: "0 0 0 10px" }}>
                  Koniec
                </div>
              </a>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default Final;
