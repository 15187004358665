import { useState } from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import regada_picture from "../images/logoTYPOGRAPHIAwhite.svg";
import sipka from "../images/icons8-arrow.svg";
import { colors } from "@mui/material";

const Nav = ({
  onSelectLanguage,
  defaultLang,
  farba,
  presmerovanie,
  landingPage,
}) => {
  const [selectedLanguage, setSelectedLanguage] = useState(defaultLang);
  const navigate = useNavigate();

  const handleLanguageChange = (event) => {
    setSelectedLanguage(event.target.value);
    onSelectLanguage(event.target.value); // Pass the selected language back to the parent component
  };

  const jazyky = ["SK", "EN", "RU"];

  return (
    <div id="headerup" style={{ backgroundColor: farba ? farba : "#005e9d" }}>
      {" "}
      {/* <Link to={menu ? "/menu" : presmerovanie ? presmerovanie : "/"}> */}
      <Link
        to={landingPage ? "/" : "https://www.regada.sk/elektricke-servopohony"}
      >
        <img id="back_header" src={sipka} alt="sipka"></img>
      </Link>
      {/* <Link id="typo_obal" to={presmerovanie ? presmerovanie : "/"}> */}
      <Link
        id="typo_obal"
        to={landingPage ? "/" : "https://www.regada.sk/elektricke-servopohony"}
      >
        <img src={regada_picture} id="typografia_header" alt="typography"></img>
      </Link>
      {defaultLang ? (
        <div>
          <select
            value={selectedLanguage}
            onChange={handleLanguageChange}
            name="language"
            id="language_header"
          >
            {jazyky.map((e) => {
              return <option value={e}>{e}</option>;
            })}
          </select>
        </div>
      ) : (
        <div style={{ width: "60px" }}></div>
      )}
    </div>
  );
};

export default Nav;
