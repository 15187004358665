import { useState, useEffect, useRef } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import Loading from "../components/LoadingScreen";
import regada_picture from "../images/logoTYPOGRAPHIAwhite.svg";
import { FaSearch } from "react-icons/fa";
import sipka_find from "../images/icons8-arrow1.svg";

import truck from "../images/truck-1058.svg";
import noveServo from "../images/new-file-svgrepo-com.svg";
import language from "../images/language-svgrepo-com.svg";
import addImage from "../images/image-combiner-svgrepo-com.svg";
import settings from "../images/settings-2-svgrepo-com.svg";
import vynimky from "../images/exclamation-triangle-svgrepo-com.svg";
import poznamky from "../images/note-sticky-svgrepo-com.svg";
import zakaznicke from "../images/customer-service-svgrepo-com.svg";
import MoreArrow from "../images/rightArrow.svg";
import globalPoznamky from "../images/world-1-svgrepo-com.svg";

import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

import Tooltip from "@mui/material/Tooltip";
import BackupIcon from "@mui/icons-material/Backup";

import LoginMenu from "../components/LoginMenu";

const Menu = () => {
  const serverAdresa = "https://vps.regada.sk/";
  // const serverAdresa = "http://localhost:8000/";

  const navigate = useNavigate();
  const location = useLocation();

  const [vsetkyServopohony, setVsetkyServopohony] = useState(null);
  const prveSpustenie = useRef(false);
  const [naspat, setNaspat] = useState(true);
  const [predaje, setPredaje] = useState(null);

  const [pocetVynimiek, setPocetVynimiek] = useState(null);

  const [typove, setTypove] = useState("");

  const [zaheslovane, setZaheslovane] = useState(true);

  if (location.state && naspat) {
    setZaheslovane(location.state.zaheslovane);
    setNaspat(false);
  }

  const [visibleItems, setVisibleItems] = useState(20);

  const [searchText, setSearchText] = useState("");

  const [lastestBackup, setLatestBackup] = useState("");

  const [kategoria, setKategoria] = useState("");
  const [otacky, setOtacky] = useState("");

  const [vyhladavanieData, setVyhladavanieData] = useState(null);

  const nastavitZaheslovanie = (e) => {
    // Increase the number of visible items by 20
    setZaheslovane(e);
  };

  const showMoreItems = () => {
    // Increase the number of visible items by 20
    setVisibleItems((prevVisibleItems) => prevVisibleItems + 20);
  };

  const vseobecneVeci = [
    "História dopytu",
    "Pridať nový pohon",
    "Slovník",
    "Nahrať obrázok",
    "Všeobecné poznámky",
  ];

  const icons = [truck, noveServo, language, addImage, globalPoznamky];
  const presmerovania = [
    "historia",
    "pridatnovy",
    "slovnik",
    "obrazky",
    "globalPoznamka",
  ];

  const prekazde = [settings, vynimky, poznamky, zakaznicke];
  const prekazdePresmerovanie = [
    "editovanie",
    "vynimky",
    "poznamky",
    "zakaznicke",
  ];

  const tooltipPopis = ["Editovať", "Výnimky", "Poznámky", "Zak. vyhotovenia"];

  async function getAllServa() {
    try {
      const response = await axios.get(`${serverAdresa}vsetkyServaPopis`);

      setVsetkyServopohony(response.data);
      setVyhladavanieData(response.data);
    } catch (e) {
      console.log(e);
    }
  }

  async function getLatestBackup() {
    try {
      const response = await axios.get(`${serverAdresa}poslednyBackup`);
      setLatestBackup(formatISODate(response.data.cas));
    } catch (e) {
      console.log(e);
    }
  }

  async function Backup() {
    try {
      await axios.get(`${serverAdresa}backup`);
    } catch (e) {
      console.log(e);
    }
  }

  async function getCountServa() {
    try {
      const response = await axios.get(`${serverAdresa}pocetServZaRok`);

      const transformedObj = {};
      response.data.forEach((item) => {
        transformedObj[item._id] = item.count;
      });
      setPredaje(transformedObj);
    } catch (e) {
      console.log(e);
    }
  }

  const handleSearching = (value) => {
    setSearchText(value);
    filterData(value);
  };

  const excludeColumns = [
    "lengthOfZakaznickeVyhotovenia",
    "kategoria",
    "otacky",
    "totalVynimkyCount",
  ];

  const filterData = (value) => {
    const lowercasedValue = value.toLowerCase().trim();

    if (lowercasedValue === "") {
      // If the search text is empty, reset to the original data
      setVyhladavanieData(vsetkyServopohony);
    } else {
      const filteredData = vsetkyServopohony.filter((item) => {
        return Object.keys(item).some((key) => {
          const itemValue = item[key];
          return (
            !excludeColumns.includes(key) &&
            itemValue !== null &&
            itemValue !== undefined &&
            itemValue.toString().toLowerCase().includes(lowercasedValue)
          );
        });
      });

      setVyhladavanieData(filteredData);
    }
  };

  const formatISODate = (isoDateString) => {
    const date = new Date(isoDateString);
    const options = { day: "2-digit", month: "2-digit", year: "numeric" };
    const datum = date.toLocaleDateString(undefined, options).split("/");

    return (
      parseInt(datum[1]).toString() +
      "." +
      parseInt(datum[0]).toString() +
      "." +
      datum[2]
    );
  };

  useEffect(() => {
    if (prveSpustenie.current === false) {
      getLatestBackup();
      getAllServa();
      getCountServa();
      return () => (prveSpustenie.current = true);
    }
  }, []);

  return (
    <>
      {vsetkyServopohony && predaje && (
        <>
          <div className={zaheslovane ? "zoslabenie" : ""}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                marginBottom: "30px",
              }}
            >
              <div className="headerup-menu">
                {" "}
                <Link id="typo_obal" to={!zaheslovane ? "/" : ""}>
                  <img
                    src={regada_picture}
                    id="typografia_header"
                    alt="typography"
                  ></img>
                </Link>
              </div>
              <div
                id="headerUpIcons"
                className="headerup-menu"
                style={{ borderTop: "1px solid #343434" }}
              >
                {vseobecneVeci.map((e, i) => {
                  return (
                    <div
                      style={{
                        display: "flex",
                        borderRight: "1px solid #343434",
                        paddingRight: "20px",
                        alignItems: "center",
                        gap: "20px",
                        cursor: "pointer",
                      }}
                      key={e + i}
                      onClick={() => {
                        if (!zaheslovane) {
                          navigate(`/${presmerovania[i]}`, {
                            state: {
                              zaheslovane,
                            },
                          });
                        }
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          border: "2px solid #3F3F3F",
                          borderRadius: "30px",
                          marginLeft: "20px",
                        }}
                      >
                        <img
                          src={icons[i]}
                          alt="truck"
                          className="menu_icons"
                        ></img>
                      </div>
                      <div>{e}</div>
                    </div>
                  );
                })}
              </div>

              <div className="vyhladavanieClass">
                <div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "10px",
                      marginTop: "20px",
                    }}
                  >
                    <div className="velkyNazov">Servopohony</div>
                    <div className="objednavky" style={{ margin: "0" }}>
                      {" "}
                      {vsetkyServopohony.length}
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      marginLeft: "30px",
                      marginTop: "5px",
                      alignItems: "center",
                      gap: "8px",
                    }}
                  >
                    <div>Posledná záloha {lastestBackup}</div>

                    <BackupIcon
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        if (!zaheslovane) {
                          Backup();
                        }
                      }}
                    />
                  </div>
                </div>

                <div
                  className="input-wrapper"
                  style={{ margin: "0", marginTop: "22px" }}
                >
                  <FaSearch
                    id="search-icon"
                    style={{ fontSize: "20px", color: "black" }}
                  />

                  <input
                    className="cislo_objednavacie"
                    placeholder="Typové číslo..."
                    onChange={(e) => {
                      if (!zaheslovane) {
                        handleSearching(e.target.value);
                      }
                    }}
                    // value={input}
                  />
                  <img
                    // onClick={handleSubmit}
                    src={sipka_find}
                    alt="sipka"
                    className="checkmark black_filter"
                  />
                </div>
                <div id="filterMenu" className="filterMenuClass">
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <FormControl
                      variant="standard"
                      sx={{ m: 1, minWidth: 120 }}
                      size="small"
                    >
                      <InputLabel id="demo-simple-select-standard-label">
                        Kategória
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-standard-label"
                        id="demo-simple-select-standard"
                        value={kategoria}
                        onChange={(e) => {
                          setKategoria(e.target.value);
                        }}
                      >
                        <MenuItem value="">
                          <em>Žiadne</em>
                        </MenuItem>

                        <MenuItem value={"standard"}>Standard</MenuItem>
                        <MenuItem value={"standard-ex"}>Standard - Ex</MenuItem>
                        <MenuItem value={"rematic"}>Rematic</MenuItem>
                        <MenuItem value={"rematic-ex"}>Rematic - Ex</MenuItem>
                      </Select>
                    </FormControl>
                    <FormControl
                      variant="standard"
                      sx={{ m: 1, minWidth: 120 }}
                      size="small"
                    >
                      <InputLabel id="demo-simple-select-standard-label">
                        Otáčky
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-standard-label"
                        id="demo-simple-select-standarda"
                        value={otacky}
                        onChange={(e) => {
                          setOtacky(e.target.value);
                        }}
                      >
                        <MenuItem value="">
                          <em>Žiadne</em>
                        </MenuItem>
                        <MenuItem value={"jedno"}>Jednootáčkové</MenuItem>
                        <MenuItem value={"viac"}>Viacotáčkové</MenuItem>
                        <MenuItem value={"priamo"}>Priamočiaré</MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                </div>
              </div>

              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  justifyContent: "center",
                  marginTop: "20px",
                  gap: "40px",
                }}
              >
                {vyhladavanieData
                  .filter((e) =>
                    kategoria === "" ? e : e.kategoria === kategoria
                  )
                  .filter((e) => (otacky === "" ? e : e.otacky === otacky))
                  .slice(0, visibleItems)
                  .map((jednoServo, indexServa) => {
                    return (
                      <div
                        className="containerCard"
                        key={jednoServo.kod + indexServa}
                      >
                        <div
                          className="dalsiContainer"
                          style={{
                            background:
                              jednoServo.kategoria[0].toUpperCase() === "S"
                                ? "#E0F3FF"
                                : "#D5F6ED",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              width: "100%",
                              justifyContent: "space-between",
                              height: "128px",
                            }}
                          >
                            <div style={{ margin: "18px 36px 0 14px" }}>
                              <div>Typ. č. {jednoServo.kod}</div>
                              <div className="nazovMenuServo">
                                {jednoServo.nazov}
                              </div>
                            </div>

                            {!zaheslovane ? (
                              <Link
                                to={`https://konfigurator.regada.sk/serva/${jednoServo.kod}`}
                                // to={`http://localhost:3000/serva/${jednoServo.kod}`}
                                target="_blank"
                              >
                                <img
                                  style={{
                                    height: "110px",
                                    marginTop: "10px",
                                    marginRight: "12px",
                                  }}
                                  src={`https://konfigurator.regada.sk/obrazkyVsetky/servopohony/${jednoServo.kod}.png`}
                                  alt="Nie je nahratý"
                                ></img>
                              </Link>
                            ) : (
                              <img
                                style={{
                                  height: "110px",
                                  marginTop: "10px",
                                  marginRight: "12px",
                                }}
                                src={`https://konfigurator.regada.sk/obrazkyVsetky/servopohony/${jednoServo.kod}.png`}
                                alt="Nie je nahratý"
                              ></img>
                            )}
                          </div>
                          <div className="kategoria">
                            {jednoServo.otacky === "jedno"
                              ? "Jednootáčkové"
                              : jednoServo.otacky === "viac"
                              ? "Viacotáčkové"
                              : "Priamočiaré"}
                          </div>
                          <div style={{ display: "flex" }}>
                            <div className="objednavky">
                              Objednávky:{" "}
                              {Object.keys(predaje).includes(jednoServo.kod)
                                ? predaje[jednoServo.kod]
                                : 0}
                            </div>
                            <div className="objednavky">
                              Výnimky:{" "}
                              {jednoServo.totalVynimkyCount
                                ? jednoServo.totalVynimkyCount
                                : 0}
                            </div>
                          </div>
                          <div className="objednavky">
                            Zákaznicke vyhotovenia:{" "}
                            {jednoServo.lengthOfZakaznickeVyhotovenia}
                          </div>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            margin: "10px",
                          }}
                        >
                          {prekazde.map((e, i) => {
                            if (!zaheslovane) {
                              return (
                                <Tooltip title={tooltipPopis[i]} arrow>
                                  <Link
                                    to={`/${
                                      prekazdePresmerovanie[i] +
                                      "/" +
                                      jednoServo.kod
                                    }`}
                                    target={"_blank"}
                                  >
                                    <div
                                      style={{
                                        display: "flex",
                                        border: "2px solid #181818",
                                        borderRadius: "30px",
                                        backgroundColor: "#181818",
                                      }}
                                    >
                                      <img
                                        src={e}
                                        alt={e}
                                        className="cardIcons"
                                      ></img>
                                    </div>
                                  </Link>
                                </Tooltip>
                              );
                            } else {
                              return (
                                <div
                                  style={{
                                    display: "flex",
                                    border: "2px solid #181818",
                                    borderRadius: "30px",
                                    backgroundColor: "#181818",
                                  }}
                                >
                                  <img
                                    src={e}
                                    alt={e}
                                    className="cardIcons"
                                  ></img>
                                </div>
                              );
                            }
                          })}
                        </div>
                      </div>
                    );
                  })}
              </div>
              {visibleItems <
                vyhladavanieData
                  .filter((e) =>
                    kategoria === "" ? e : e.kategoria === kategoria
                  )
                  .filter((e) => (otacky === "" ? e : e.otacky === otacky))
                  .length &&
                typove === "" && (
                  <img
                    src={MoreArrow}
                    alt="more"
                    style={{
                      transform: "rotate(90deg)",
                      width: "20px",
                      cursor: "pointer",
                      margin: "30px 0",
                    }}
                    onClick={showMoreItems}
                  ></img>
                )}
            </div>
          </div>

          {zaheslovane && (
            <LoginMenu nastavitZaheslovanie={nastavitZaheslovanie} />
          )}
        </>
      )}
      {(!vsetkyServopohony || !predaje) && <Loading color={"#141414"} />}
    </>
  );
};

export default Menu;
