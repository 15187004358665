import { Link } from "react-router-dom";
import { useState } from "react";

import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";

const LoginMenu = ({ nastavitZaheslovanie }) => {
  const [oddelenie, setOddelenie] = useState("obchodne");
  const [heslo, setHeslo] = useState("");
  const [error, setError] = useState(null);

  const handleSubmit = (e) => {
    e.preventDefault(); // Prevents the default form submission behavior

    // zahasovat heslo
    if (oddelenie && heslo) {
      if (oddelenie === "obchodne" && heslo === "regada") {
        nastavitZaheslovanie(false);
        // nastavitCookie("obchodne");
      } else if (heslo === "regada") {
        nastavitZaheslovanie(false);
        // nastavitCookie("technicke");
      } else {
        setError("Nesprávne heslo");
        setHeslo("");
      }
    } else if (!oddelenie) {
      setError("Vyberte oddelenie");
    } else {
      setError("Nesprávne heslo");
    }
  };

  return (
    <div className="auth-modal-login">
      <Link to="/">
        <div className="close-icon">✖</div>
      </Link>
      <div id="nadpis_form-login">Pre pokračovanie zadajte heslo</div>

      <FormControl style={{ marginTop: "20px", marginBottom: "10px" }}>
        <FormLabel>Oddelenie</FormLabel>
        <RadioGroup
          row
          aria-labelledby="demo-row-radio-buttons-group-label"
          name="row-radio-buttons-group"
          value={oddelenie}
          onChange={(e) => {
            setOddelenie(e.target.value);
            setError(null);
          }}
        >
          <FormControlLabel
            value="technicke"
            control={<Radio />}
            label="Technické"
          />
          <FormControlLabel
            value="obchodne"
            control={<Radio />}
            label="Obchodné"
          />
        </RadioGroup>
      </FormControl>
      <div style={{ marginBottom: "5px" }}>{error}</div>

      <Box
        component="form"
        sx={{
          "& > :not(style)": { m: 0, width: "25ch" },
        }}
        noValidate
        autoComplete="off"
        onSubmit={handleSubmit}
      >
        <TextField
          label="Heslo"
          variant="filled"
          type="password"
          value={heslo}
          onChange={(e) => {
            setHeslo(e.target.value);
            setError(null);
          }}
        />
      </Box>

      <div className="tlacitka-formular" style={{ marginTop: "220px" }}>
        <Link to="/">
          <button
            className="tlacitko"
            style={{ padding: "10px 0 10px 0", width: "120px" }}
          >
            ← Zrušiť
          </button>
        </Link>
        <button
          className="tlacitko"
          style={{ padding: "10px 0 10px 0", width: "120px" }}
          onClick={() => {
            if (oddelenie && heslo) {
              if (oddelenie === "obchodne" && heslo === "37g2cuQmNp") {
                nastavitZaheslovanie(false);
              } else if (heslo === "D496neAufJ") {
                nastavitZaheslovanie(false);
              } else {
                setError("Nesprávne heslo");
                setHeslo("");
              }
            } else if (!oddelenie) {
              setError("Vyberte oddelenie");
            } else {
              setError("Nesprávne heslo");
            }
          }}
        >
          Potvrď →
        </button>
      </div>
    </div>
  );
};
export default LoginMenu;
