import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Checkbox from "@mui/material/Checkbox";
import { red } from "@mui/material/colors";

const Formular = ({
  setShowModal,
  vsetkyUdaje,
  stlaceneCele,
  typove,
  ktoryNazov,
  celyRiadok,
  rozsireneVsetky,
  zakaznicke,
  slovnik,
  selectedLanguage,
  poznamka,
  najdenePoznamky,
  zakaznickeVyhotovenieNajdene,
}) => {
  const [meno, setMeno] = useState("");
  const [firma, setFirma] = useState("");
  const [email, setEmail] = useState("");
  const [tel, setTel] = useState("");
  const [oznacenie, setOznacenie] = useState("");
  const [sprava, setSprava] = useState("");

  const [GDPR, setGDPR] = useState(false);
  const [error, setError] = useState(false);

  let navigate = useNavigate();

  const handleClickModal = () => {
    setShowModal(false);
  };

  return (
    <div className="auth-modal">
      <div className="close-icon" onClick={handleClickModal}>
        ✖
      </div>
      <div id="nadpis_form">
        {selectedLanguage === "EN"
          ? "Please fill in your contact information"
          : selectedLanguage === "RU"
          ? "Пожалуйста, заполните ваши контактные данные"
          : "Prosím, vyplňte vaše kontaktné údaje"}
      </div>
      <div id="popis_form">
        {selectedLanguage === "EN"
          ? "This inquiry will be sent to Regada, s.r.o., Strojnícka 7. You will receive a copy of the configuration to the e-mail address of your choice."
          : selectedLanguage === "RU"
          ? "Этот запрос будет отправлен в адрес Regada, s.r.o., Strojnícka 7. Вы получите копию конфигурации на выбранный вами адрес электронной почты."
          : "Tento dopyt bude odoslaný do spol. Regada, s.r.o., Strojnícka 7. Kópiu konfigurácie obdržíte na vami zvolenú E-mailovú adresu."}
      </div>
      <div className="form_inputy">
        <label htmlFor="first_name">
          {" "}
          {selectedLanguage === "EN"
            ? "Name and surname"
            : selectedLanguage === "RU"
            ? "Имя и фамилия"
            : "Meno a priezvisko"}
        </label>
        <input
          autoFocus
          type="text"
          id="first_name"
          name="first_name"
          placeholder={
            selectedLanguage === "EN"
              ? "Name and surname"
              : selectedLanguage === "RU"
              ? "Имя и фамилия"
              : "Meno a priezvisko žiadateľa"
          }
          value={meno}
          onChange={(e) => setMeno(e.target.value)}
        />
        <label htmlFor="firma">
          {" "}
          {selectedLanguage === "EN"
            ? "Company"
            : selectedLanguage === "RU"
            ? "Компания"
            : "Firma"}
        </label>

        <input
          type="text"
          id="firma"
          name="firma"
          placeholder={
            selectedLanguage === "EN"
              ? "Company"
              : selectedLanguage === "RU"
              ? "Компания"
              : "Názov firmy"
          }
          value={firma}
          onChange={(e) => setFirma(e.target.value)}
        />
        <label htmlFor="email">
          {" "}
          {selectedLanguage === "EN"
            ? "E-mail adress"
            : selectedLanguage === "RU"
            ? "Электронный адрес"
            : "E-mailová adresa"}
        </label>
        <input
          type="email"
          id="email"
          name="email"
          placeholder={
            selectedLanguage === "EN"
              ? "E-mail adress where PDF configuration will be sent"
              : selectedLanguage === "RU"
              ? "Адрес электронной почты, на который будет отправлена конфигурация в формате PDF."
              : "E-mailová adresa na ktorú bude zaslane PDF s konfiguráciou"
          }
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <label htmlFor="telefon">
          {" "}
          {selectedLanguage === "EN"
            ? "Tel. number"
            : selectedLanguage === "RU"
            ? "Тел. число"
            : "Tel. číslo"}
        </label>
        <input
          type="tel"
          id="telefon"
          name="telefon"
          placeholder={
            selectedLanguage === "EN"
              ? "Tel. number"
              : selectedLanguage === "RU"
              ? "Тел. число"
              : "Tel. číslo"
          }
          value={tel}
          onChange={(e) => setTel(e.target.value)}
        />
        <label htmlFor="oznacenie">
          {" "}
          {selectedLanguage === "EN"
            ? "Custom label"
            : selectedLanguage === "RU"
            ? "Пользовательская этикетка"
            : "Vlastné označenie"}
        </label>
        <input
          type="text"
          id="oznacenie"
          name="oznacenie"
          placeholder={
            selectedLanguage === "EN"
              ? "Custom label"
              : selectedLanguage === "RU"
              ? "Пользовательская этикетка"
              : "Vlastné označenie"
          }
          value={oznacenie}
          onChange={(e) => setOznacenie(e.target.value)}
        />
        <label htmlFor="sprava">
          {" "}
          {selectedLanguage === "EN"
            ? "Message"
            : selectedLanguage === "RU"
            ? "Сообщение"
            : "Správa"}
        </label>

        <textarea
          maxLength="300"
          type="text"
          id="sprava"
          name="sprava"
          placeholder={
            selectedLanguage === "EN"
              ? "Message max 300 characters"
              : selectedLanguage === "RU"
              ? "Сообщение максимум 300 символов"
              : "Správa max 300 znakov"
          }
          value={sprava}
          onChange={(e) => setSprava(e.target.value)}
        ></textarea>
      </div>
      <div style={{ display: "flex", alignItems: "center" }}>
        <Checkbox
          value={GDPR}
          onChange={() => {
            setGDPR(!GDPR);
            setError(false);
          }}
          sx={{
            color: !error ? "default" : red[600],
          }}
          size="small"
        />
        <div id="GDPR">
          {" "}
          {selectedLanguage === "EN"
            ? "I agree to the processing of personal data."
            : selectedLanguage === "RU"
            ? "Я согласен на обработку персональных данных."
            : "Súhlasím so spracovaním osobných údajov."}{" "}
        </div>
        <Link
          to="https://www.regada.sk/en/zasady-ochrany-osobnych-udajov-qnf"
          target="_blank"
        >
          <div style={{ marginLeft: "3px" }}>
            {selectedLanguage === "EN"
              ? "More."
              : selectedLanguage === "RU"
              ? "Более."
              : "Viac."}
          </div>
        </Link>
      </div>
      <div className="tlacitka-formular">
        <button
          className="tlacitko"
          onClick={handleClickModal}
          style={{ padding: "10px 0 10px 0", width: "180px" }}
        >
          ←{" "}
          {selectedLanguage === "EN"
            ? "Back"
            : selectedLanguage === "RU"
            ? "Назад"
            : "Naspäť"}
        </button>

        <button
          className="tlacitko"
          style={{ padding: "10px 0 10px 0", width: "180px" }}
          onClick={() => {
            if (GDPR) {
              navigate("/final", {
                state: {
                  meno,
                  firma,
                  email,
                  tel,
                  oznacenie,
                  sprava,
                  vsetkyUdaje,
                  stlaceneCele,
                  typove,
                  ktoryNazov,
                  celyRiadok,
                  rozsireneVsetky,
                  zakaznicke,
                  slovnik,
                  selectedLanguage,
                  poznamka,
                  najdenePoznamky,
                  zakaznickeVyhotovenieNajdene,
                },
              });
            } else {
              setError(true);
            }
          }}
        >
          {selectedLanguage === "EN"
            ? "Continue"
            : selectedLanguage === "RU"
            ? "Продолжать"
            : "Potvrď"}{" "}
          →
        </button>
      </div>
    </div>
  );
};
export default Formular;
