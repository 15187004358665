import Tooltip from "@mui/material/Tooltip";
import HornyIndex from "./HornyIndex";

const Hodnota = ({
  hodnotaRiadok,
  slovnik,
  selectedLanguage,
  poznamka,
  velkostFlexu,
}) => {
  if (hodnotaRiadok.includes(")")) {
    let rozdelene = hodnotaRiadok.split(" ");
    let poznamky = rozdelene.filter(
      (e) => e.includes(")") & !isNaN(e.slice(0, -1))
    );
    let text_hodnota = rozdelene.filter((e) => !poznamky.includes(e)).join(" ");

    return (
      <div
        className="table-data"
        style={{
          display: "flex",
          justifyContent: "center",
          gap: "5px",
          flex: velkostFlexu ? velkostFlexu : "",
        }}
      >
        <div>
          {slovnik ? (
            slovnik.hasOwnProperty(text_hodnota) ? (
              selectedLanguage === "EN" ? (
                <HornyIndex hodnotaRiadok={slovnik[text_hodnota][0]} />
              ) : selectedLanguage === "RU" ? (
                <HornyIndex hodnotaRiadok={slovnik[text_hodnota][1]} />
              ) : (
                <HornyIndex hodnotaRiadok={text_hodnota} />
              )
            ) : (
              <HornyIndex hodnotaRiadok={text_hodnota} />
            )
          ) : (
            <HornyIndex hodnotaRiadok={text_hodnota} />
          )}
        </div>
        {poznamky.map((e, i) => {
          let poznamkaUpravena = "";
          if (poznamka) {
            if (poznamka[e.slice(0, -1)]) {
              poznamkaUpravena = poznamka[e.slice(0, -1)];
            } else {
              poznamkaUpravena = "Poznámka nebola nájdena";
            }
          } else {
            poznamkaUpravena = "Poznámka nebola nájdena";
          }
          return (
            <Tooltip
              title={
                slovnik
                  ? slovnik.hasOwnProperty(poznamkaUpravena)
                    ? selectedLanguage === "EN"
                      ? slovnik[poznamkaUpravena][0]
                      : selectedLanguage === "RU"
                      ? slovnik[poznamkaUpravena][1]
                      : poznamkaUpravena
                    : poznamkaUpravena
                  : poznamkaUpravena
              }
            >
              <div className="tooltipExtra">{e}</div>
            </Tooltip>
          );
        })}
      </div>
    );
  }
  return (
    <div
      className="table-data"
      style={{ flex: velkostFlexu ? velkostFlexu : "" }}
    >
      {/* pre -1 */}
      {slovnik ? (
        slovnik.hasOwnProperty(hodnotaRiadok) ? (
          selectedLanguage === "EN" ? (
            <HornyIndex hodnotaRiadok={slovnik[hodnotaRiadok][0]} />
          ) : selectedLanguage === "RU" ? (
            <HornyIndex hodnotaRiadok={slovnik[hodnotaRiadok][1]} />
          ) : (
            <HornyIndex hodnotaRiadok={hodnotaRiadok} />
          )
        ) : (
          <HornyIndex hodnotaRiadok={hodnotaRiadok} />
        )
      ) : (
        <HornyIndex hodnotaRiadok={hodnotaRiadok} />
      )}
    </div>
  );
};

export default Hodnota;
