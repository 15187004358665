const HornyIndex = ({ hodnotaRiadok }) => {
  if (hodnotaRiadok.slice(-2) === "-1") {
    return (
      <>
        {hodnotaRiadok.slice(0, -2)}
        <sup>{hodnotaRiadok.slice(-2)}</sup>
      </>
    );
  }
  return hodnotaRiadok;
};

export default HornyIndex;
