import Tooltip from "@mui/material/Tooltip";
import Preklad from "../Preklad";

const SiedmaTabulkaConfi = ({
  nevybrateMoznosti,
  vsetkyUdaje,
  slovnik,
  selectedLanguage,
  pismenka,
  kombinaciePismena,
  najdene,
  kombinacieSpolu,
  updateKtoraTab,
  nastavitPismenka,
  konektor,
  nastavitFinalSchemy,
  poznamka,
}) => {
  const handleHover = (i) => {
    updateKtoraTab(i);
  };

  function handleSpecialClick(pismenko, index, riadok) {
    let schema = riadok.slice(-3, -2).join("");
    schema = schema.split(" ").filter((e) => e !== "/");
    if (!konektor) {
      schema = schema.filter((e, i) => i % 2 !== 1).join("+");
    } else {
      schema = schema.filter((e, i) => i % 2 !== 0).join("+");
    }
    // ked je ich viac v array
    if (pismenka.includes(pismenko) && pismenka.length > 1) {
      nastavitPismenka(pismenka.filter((a) => a !== pismenko));
      nastavitFinalSchemy((prevArr) => {
        const newArr = [...prevArr];

        newArr[newArr.length - 1] = newArr[newArr.length - 1]
          .split(" ")
          .filter((e) => e !== schema)
          .join(" ");

        return newArr;
      });
    }
    // ked je len jedno pismenko v array
    else if (pismenka.includes(pismenko) && pismenka.length === 1) {
      nastavitPismenka([]);
      nastavitFinalSchemy((prevArr) => {
        const newArr = [...prevArr];

        newArr[newArr.length - 1] = "";

        return newArr;
      });
    }
    // pridavam, vyskytuje sa
    else {
      // setPismenka(...pismenka, pismenko);
      nastavitPismenka((prevState) => [...prevState, pismenko]);
      if (schema.length > 1) {
        nastavitFinalSchemy((prevArr) => {
          const newArr = [...prevArr];

          if (newArr[newArr.length - 1] === "") {
            newArr[newArr.length - 1] = schema;
          } else {
            newArr[newArr.length - 1] =
              newArr[newArr.length - 1] + " " + schema;
          }

          return newArr;
        });
      }
    }
  }

  return (
    <div hidden={nevybrateMoznosti} className="siedma_tabulka">
      {/* Nazvy */}
      {/* <div className="table-header" style={{ padding: 0 }}> */}
      <div className="cele_nazvy" style={{ width: "99.9%" }}>
        {vsetkyUdaje.tabulka7.nazvy.map((nazov, i) => {
          if (Array.isArray(nazov)) {
            let pocet_stlpcov = nazov[nazov.length - 1].length;
            return (
              <div key={i} className={"group flex" + pocet_stlpcov}>
                {nazov.map((podnazov, indexPodnazov) => {
                  return (
                    <div key={indexPodnazov} className="row">
                      {podnazov.map((nazovInGroup, nazovInGroupIndex) => {
                        return (
                          <div
                            key={"6" + nazovInGroupIndex}
                            className="element_nazov"
                          >
                            {slovnik.hasOwnProperty(nazovInGroup)
                              ? selectedLanguage === "EN"
                                ? slovnik[nazovInGroup][0]
                                : selectedLanguage === "RU"
                                ? slovnik[nazovInGroup][1]
                                : nazovInGroup
                              : nazovInGroup}
                          </div>
                        );
                      })}
                    </div>
                  );
                })}
              </div>
            );
          }
          return (
            <div className="row">
              <div key={"6" + i} className="element_nazov">
                {slovnik.hasOwnProperty(nazov)
                  ? selectedLanguage === "EN"
                    ? slovnik[nazov][0]
                    : selectedLanguage === "RU"
                    ? slovnik[nazov][1]
                    : nazov
                  : nazov}
              </div>
            </div>
          );
        })}
        <div className="row">
          <div className="element_nazov">↓</div>
        </div>
        <div className="row">
          <div className="element_nazov">↓</div>
        </div>
      </div>

      <div className="mobile_nazvy">
        {vsetkyUdaje.tabulka7.nazvy.length > 1 ? (
          Array.isArray(vsetkyUdaje.tabulka7.nazvy[1]) ? (
            <Preklad
              hodnota={vsetkyUdaje.tabulka7.nazvy[1][0]}
              jazyk={selectedLanguage}
              prekladac={slovnik}
            />
          ) : (
            <Preklad
              hodnota={vsetkyUdaje.tabulka7.nazvy[1]}
              jazyk={selectedLanguage}
              prekladac={slovnik}
            />
          )
        ) : (
          "Názov nie je pridaný"
        )}
      </div>

      {/* Hodnoty */}
      <div className="table-content">
        {" "}
        {vsetkyUdaje.tabulka7.hodnoty.map((hodnota, index) => {
          let indexy = 8;
          let pismenko = hodnota[0];
          return (
            <div
              key={indexy.toString() + index}
              //   DOKONCIT
              onClick={() => {
                handleSpecialClick(pismenko, index, hodnota);
              }}
              onMouseOver={() => handleHover(indexy)}
              className={
                pismenka.includes(pismenko)
                  ? "stisknute table-row-siedma"
                  : "nestisknute table-row-siedma"
              }
            >
              {hodnota.map((hodnotaRiadok, i) => {
                if (hodnotaRiadok.includes(")")) {
                  let rozdelene = hodnotaRiadok.split(" ");
                  let poznamky = rozdelene.filter(
                    (e) => e.includes(")") & !isNaN(e.slice(0, -1))
                  );
                  let text_hodnota = rozdelene
                    .filter((e) => !poznamky.includes(e))
                    .join(" ");
                  return (
                    <div
                      className="siedma_data"
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        gap: "5px",
                      }}
                    >
                      <div>
                        {slovnik
                          ? slovnik.hasOwnProperty(text_hodnota)
                            ? selectedLanguage === "EN"
                              ? slovnik[text_hodnota][0]
                              : selectedLanguage === "RU"
                              ? slovnik[text_hodnota][1]
                              : text_hodnota
                            : text_hodnota
                          : text_hodnota}
                      </div>
                      {poznamky.map((e, i) => {
                        let poznamkaUpravena = "";
                        if (poznamka) {
                          if (poznamka[e.slice(0, -1)]) {
                            poznamkaUpravena = poznamka[e.slice(0, -1)];
                          } else {
                            poznamkaUpravena = "Poznámka nebola nájdena";
                          }
                        } else {
                          poznamkaUpravena = "Poznámka nebola nájdena";
                        }
                        return (
                          <Tooltip
                            title={
                              slovnik
                                ? slovnik.hasOwnProperty(poznamkaUpravena)
                                  ? selectedLanguage === "EN"
                                    ? slovnik[poznamkaUpravena][0]
                                    : selectedLanguage === "RU"
                                    ? slovnik[poznamkaUpravena][1]
                                    : poznamkaUpravena
                                  : poznamkaUpravena
                                : poznamkaUpravena
                            }
                          >
                            <div className="tooltipExtra">{e}</div>
                          </Tooltip>
                        );
                      })}
                    </div>
                  );
                }

                return (
                  <div className="siedma_data">
                    {slovnik.hasOwnProperty(hodnotaRiadok)
                      ? selectedLanguage === "EN"
                        ? slovnik[hodnotaRiadok][0]
                        : selectedLanguage === "RU"
                        ? slovnik[hodnotaRiadok][1]
                        : hodnotaRiadok
                      : hodnotaRiadok}
                  </div>
                );
              })}
            </div>
          );
        })}
        <div
          hidden={
            kombinaciePismena.filter((e) => e.length > 1).length > 0
              ? false
              : true
          }
          style={{ width: "99.9%" }}
        >
          <div className="dov_kombinacie">
            <div id="kombinacie">
              {" "}
              {selectedLanguage === "EN"
                ? "Allowed combination and code: "
                : selectedLanguage === "RU"
                ? "Разрешенные комбинации и код исполнения: "
                : "Dovolené kombinácie a kódy: "}
              {kombinaciePismena.map((elem, indexKombinacii) => {
                if (
                  elem.length > 1 &&
                  !najdene[7].includes(kombinacieSpolu[elem])
                ) {
                  if (indexKombinacii === kombinaciePismena.length - 1) {
                    return (
                      elem.split("").join("+") + "=" + kombinacieSpolu[elem]
                    );
                  }
                  return (
                    elem.split("").join("+") +
                    "=" +
                    kombinacieSpolu[elem] +
                    " | "
                  );
                }
                return null;
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SiedmaTabulkaConfi;
